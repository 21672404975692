import React from "react";
import { useState } from "react";
import "../css/Formulario.css"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ModalReu from "../../Modal/ModalReu";
import Informativas from "../Componentes/Informativas";


function Formulario() {





    ChartJS.register(ArcElement, Tooltip, Legend);
    let visual = 0;
    let kinestesico = 0;
    let auditivo = 0;
    let totalporcentaje = 0
    let valuekine = 0
    let valuevisual = 0
    let valueauditivo = 0

    const imagenPersonas = require.context("../img/Personas", true);
    const [stateValue, setStateValue] = useState(
        {
            labels: ['Kinestesico', 'Visual', 'Auditivo'],
            datasets: [
                {
                    label: '# of Votes',
                    data: [valuekine.toFixed(2), valuevisual.toFixed(2), valueauditivo.toFixed(2)],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        }
    );

    const [estadoModal1, cambiarEstadoMordal1] = useState(false);

    const obtenervalor = () => {



        var value = $("input[type=radio][name=pregunta1]:checked").val();
        var value2 = $("input[type=radio][name=pregunta2]:checked").val();
        var value3 = $("input[type=radio][name=pregunta3]:checked").val();
        var value4 = $("input[type=radio][name=pregunta4]:checked").val();
        var value5 = $("input[type=radio][name=pregunta5]:checked").val();
        var value6 = $("input[type=radio][name=pregunta6]:checked").val();
        var value7 = $("input[type=radio][name=pregunta7]:checked").val();
        var value8 = $("input[type=radio][name=pregunta8]:checked").val();
        var value9 = $("input[type=radio][name=pregunta9]:checked").val();
        var value10 = $("input[type=radio][name=pregunta10]:checked").val();
        var value11 = $("input[type=radio][name=pregunta11]:checked").val();
        var value12 = $("input[type=radio][name=pregunta12]:checked").val();
        var value13 = $("input[type=radio][name=pregunta13]:checked").val();
        var value14 = $("input[type=radio][name=pregunta14]:checked").val();
        var value15 = $("input[type=radio][name=pregunta15]:checked").val();
        var value16 = $("input[type=radio][name=pregunta16]:checked").val();
        var value17 = $("input[type=radio][name=pregunta17]:checked").val();
        var value18 = $("input[type=radio][name=pregunta18]:checked").val();
        var value19 = $("input[type=radio][name=pregunta19]:checked").val();
        var value20 = $("input[type=radio][name=pregunta20]:checked").val();
        var value21 = $("input[type=radio][name=pregunta21]:checked").val();
        var value22 = $("input[type=radio][name=pregunta22]:checked").val();
        var value23 = $("input[type=radio][name=pregunta23]:checked").val();
        var value24 = $("input[type=radio][name=pregunta24]:checked").val();
        var value25 = $("input[type=radio][name=pregunta25]:checked").val();
        var value26 = $("input[type=radio][name=pregunta26]:checked").val();
        var value27 = $("input[type=radio][name=pregunta27]:checked").val();
        var value28 = $("input[type=radio][name=pregunta28]:checked").val();
        var value29 = $("input[type=radio][name=pregunta29]:checked").val();
        var value30 = $("input[type=radio][name=pregunta30]:checked").val();
        var value31 = $("input[type=radio][name=pregunta31]:checked").val();
        var value32 = $("input[type=radio][name=pregunta32]:checked").val();
        var value33 = $("input[type=radio][name=pregunta33]:checked").val();
        var value34 = $("input[type=radio][name=pregunta34]:checked").val();
        var value35 = $("input[type=radio][name=pregunta35]:checked").val();
        var value36 = $("input[type=radio][name=pregunta36]:checked").val();



        if (value && value2 && value3 && value4 && value5 && value6 && value7 && value8 && value9 && value10 && value11 && value12 && value13 && value14 && value15 && value16 && value17 && value18 && value19 && value20 && value21 && value22 && value23 && value24 && value25 && value26 && value27 && value28 && value29 && value30 && value31 && value32 && value33 && value34 && value35 && value36) {

            kinestesico = parseFloat(value4) + parseFloat(value6) + parseFloat(value7) + parseFloat(value8) + parseFloat(value14) + parseFloat(value18) + parseFloat(value21) + parseFloat(value25) + parseFloat(value30) + parseFloat(value31) + parseFloat(value34) + parseFloat(value35);
            auditivo = parseFloat(value2) + parseFloat(value3) + parseFloat(value12) + parseFloat(value13) + parseFloat(value15) + parseFloat(value19) + parseFloat(value20) + parseFloat(value23) + parseFloat(value24) + parseFloat(value28) + parseFloat(value29) + parseFloat(value33);
            visual = parseFloat(value) + parseFloat(value5) + parseFloat(value9) + parseFloat(value10) + parseFloat(value11) + parseFloat(value16) + parseFloat(value17) + parseFloat(value2) + parseFloat(value26) + parseFloat(value27) + parseFloat(value32) + parseFloat(value36);

            totalporcentaje = parseFloat(kinestesico) + parseFloat(auditivo) + parseFloat(visual)

            valuekine = Math.floor(kinestesico * 100) / totalporcentaje
            valuevisual = Math.floor(visual * 100) / totalporcentaje
            valueauditivo = Math.floor(auditivo * 100) / totalporcentaje

            $("#suma1").text("Puntaje Kinestesico: " + kinestesico)
            $("#suma2").text("Puntaje Auditivo: " + auditivo)
            $("#suma3").text("Puntaje Visual: " + visual)
            $("#suma4").text(valuekine.toFixed(2) + "% Kinetesico")
            $("#suma5").text(valueauditivo.toFixed(2) + "% Auditivo")
            $("#suma6").text(valuevisual.toFixed(2) + "% Visual")


            setStateValue({
                labels: ['Kinestesico', 'Visual', 'Auditivo'],
                datasets: [
                    {
                        label: 'Puntake',
                        data: [valuekine.toFixed(2), valuevisual.toFixed(2), valueauditivo.toFixed(2)],
                        backgroundColor: [
                            'rgba(255, 99, 132)',
                            'rgba(54, 162, 235)',
                            'rgba(255, 206, 86)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            });

            $("#containerGrafica").css("display", "flex")

            if (valuekine.toFixed(2) > valuevisual.toFixed(2) && valuekine.toFixed(2) > valueauditivo.toFixed(2)) {
                console.log("eres kinestesico")
                $("input").prop('disabled', true);
                $("button").prop('disabled', true);
                cambiarEstadoMordal1(!estadoModal1)

            } else if (valuevisual.toFixed(2) > valuekine.toFixed(2) && valuevisual.toFixed(2) > valueauditivo.toFixed(2)) {
                console.log("Eres Visual")
                $("input").prop('disabled', true);
                $("button").prop('disabled', true);
                cambiarEstadoMordal1(!estadoModal1)

            } else if (valueauditivo.toFixed(2) > valuekine.toFixed(2) && valueauditivo.toFixed(2) > valuevisual.toFixed(2)) {
                console.log("Eres Auditivo")
                $("input").prop('disabled', true);
                $("button").prop('disabled', true);
                cambiarEstadoMordal1(!estadoModal1)
            }
        }
        else {
            alert("Debes responder todas las preguntas")

        }
    }






    return (
        <>
            <ModalReu
                estado={estadoModal1}
                cambiarEstado={cambiarEstadoMordal1}
            >
                <div className="WrapperModal">
                    <div>
                        <h3>Canal Visual</h3>
                        <p>Si tienes preferencia hacia este canal
                            se te facilita más recordar lo que lees o visualizas (infografías, videos,
                            películas, etc.), así como crear relaciones entre ideas, potenciando tu
                            capacidad de abstracción. Es recomendable que en tus métodos de estudio hagas
                            uso de gráficas, cartillas, diapositivas, organizadores gráficos como mapas
                            conceptuales, mapas mentales, organigramas, tablas comparativas, diagramas,
                            etc. </p>
                    </div>
                    <div>
                        <h3>Canal Kinestésico</h3>
                        <p>Si este es tu canal de
                            preferencia, tiendes a asociar el conocimiento a movimientos, sensaciones, o
                            actividades físicas; prefieres aprender interactuando de manera directa con el
                            material de trabajo, realizando dibujos, experimentos, practicando ejercicios,
                            entre otros. Es recomendable que emplees ejercicios prácticos y técnicas de
                            estudio activas relacionadas con tu contexto, incluyendo momentos de descanso
                            entre las actividades académicas.</p>
                    </div>
                    <div>
                        <h3>Canal Auditivo</h3>
                        <p> Si tienes preferencia hacia este
                            canal, tu aprendizaje se desarrolla de manera más secuencial y se te facilita
                            el aprendizaje cuando escuchas al profesor, compañero o tutor que te esté
                            explicando sobre algún tema, por lo que te adaptas con facilidad a las clases
                            expositivas. Es recomendable que utilices recursos sonoros para estudiar, así
                            como ejercicios orales de discusión sobre los contenidos para reforzar tu
                            aprendizaje.</p>
                    </div>
                </div>

            </ModalReu>

            <Informativas
                titulo='TEST PARA DETERMINAR EL CANAL DE APRENDIZAJE DE PREFERENCIA. Lynn O’Brien (1990)'
                descripcion=''
                logo={imagenPersonas("./fotocie.jpg")}
                color='#F3C431'
            />

            <div
                className='modal fade'
                id='ModalFormulario'
                tabIndex='-1'
                role='dialog'
                aria-labelledby='exampleModalLabel'
                aria-hidden='true'
            >
                <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='ModalFormulario'>
                                Modal title
                            </h5>
                            <button
                                type='button'
                                className='close'
                                data-dismiss='modal'
                                aria-label='Close'
                            >
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body'>...</div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                data-dismiss='modal'
                            >
                                Close
                            </button>
                            <button type='button' className='btn btn-primary'>
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container formularios">

                <div>
                    <h3>¿Cómo aprendo?</h3>
                    <p>Lea cuidadosamente cada oración y piense de qué manera se aplica a usted.<br /><br />En cada línea escriba el número que mejor describe su reacción a cada oración.
                        <br />Casi siempre: 5.      Frecuentemente: 4.      A veces: 3.      Rara vez: 2.     Casi nunca: 1.'</p>
                    <table className="table table-hover table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col"></th>
                                <th className="numberHeader" scope="col">5</th>
                                <th className="numberHeader" scope="col">4</th>
                                <th className="numberHeader" scope="col">3</th>
                                <th className="numberHeader" scope="col">2</th>
                                <th className="numberHeader" scope="col">1</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <th scope="row">Puedo recordar algo mejor si lo escribo</th>
                                <td><input type="radio" name="pregunta1" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta1" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta1" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta1" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta1" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Al leer, oigo las palabras en mi cabeza o leo en voz alta.</th>
                                <td><input type="radio" name="pregunta2" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta2" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta2" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta2" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta2" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Necesito hablar las cosas para entenderlas mejor.</th>
                                <td><input type="radio" name="pregunta3" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta3" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta3" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta3" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta3" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">No me gusta leer o escuchar instrucciones, prefiero simplemente comenzar a hacer las cosas.</th>
                                <td><input type="radio" name="pregunta4" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta4" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta4" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta4" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta4" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Puedo visualizar imágenes en mi cabeza.</th>
                                <td><input type="radio" name="pregunta5" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta5" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta5" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta5" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta5" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Puedo estudiar mejor si escucho música.</th>
                                <td><input type="radio" name="pregunta6" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta6" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta6" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta6" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta6" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Necesito recreos frecuentes cuando estudio.</th>
                                <td><input type="radio" name="pregunta7" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta7" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta7" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta7" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta7" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Pienso mejor cuando tengo la libertad de moverme, estar sentado detrás de un escritorio no es para mí escritorio no es para mi.</th>
                                <td><input type="radio" name="pregunta8" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta8" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta8" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta8" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta8" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Tomo muchas notas de lo que leo y escucho.</th>
                                <td><input type="radio" name="pregunta9" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta9" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta9" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta9" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta9" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Me ayuda mirar a la persona que está hablando. Me mantiene enfocado.</th>
                                <td><input type="radio" name="pregunta10" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta10" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta10" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta10" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta10" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Se me hace difícil entender lo que una persona está diciendo si hay ruidos alrededor.</th>
                                <td><input type="radio" name="pregunta11" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta11" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta11" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta11" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta11" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Prefiero que alguien me diga cómo tengo que hacer las cosas que leer las instrucciones.</th>
                                <td><input type="radio" name="pregunta12" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta12" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta12" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta12" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta12" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Prefiero escuchar una conferencia o una grabación a leer un libro.</th>
                                <td><input type="radio" name="pregunta13" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta13" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta13" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta13" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta13" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Cuando no puedo pensar en una palabra específica, uso mis manos y llamo al objeto "coso".</th>
                                <td><input type="radio" name="pregunta14" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta14" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta14" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta14" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta14" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Puedo seguir fácilmente a una persona que está hablando aunque mi cabeza esté hacia abajo o me encuentre mirando por la ventana.</th>
                                <td><input type="radio" name="pregunta15" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta15" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta15" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta15" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta15" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Es más fácil para mí hacer un trabajo en un lugar tranquilo.</th>
                                <td><input type="radio" name="pregunta16" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta16" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta16" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta16" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta16" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Me resulta fácil entender mapas, tablas y gráficos.</th>
                                <td><input type="radio" name="pregunta17" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta17" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta17" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta17" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta17" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Cuando comienzo un artículo o un libro, prefiero espiar la última página.</th>
                                <td><input type="radio" name="pregunta18" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta18" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta18" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta18" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta18" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Recuerdo mejor lo que la gente dice que su aspecto.</th>
                                <td><input type="radio" name="pregunta19" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta19" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta19" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta19" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta19" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Recuerdo mejor si estudio en voz alta con alguien.</th>
                                <td><input type="radio" name="pregunta20" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta20" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta20" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta20" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta20" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Tomo notas, pero nunca vuelvo a releerlas.</th>
                                <td><input type="radio" name="pregunta21" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta21" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta21" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta21" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta21" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Cuando estoy concentrado leyendo o escribiendo, la radio me molesta.</th>
                                <td><input type="radio" name="pregunta22" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta22" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta22" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta22" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta22" id="pregunta" value="1" /></td>
                            </tr>

                            <tr>
                                <th scope="row">Me resulta difícil crear imágenes en mi cabeza.</th>
                                <td><input type="radio" name="pregunta23" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta23" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta23" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta23" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta23" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Me resulta útil decir en voz alta las tareas que tengo para hacer.</th>
                                <td><input type="radio" name="pregunta24" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta24" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta24" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta24" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta24" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Mi cuaderno y mi escritorio pueden verse un desastre, pero sé exactamente dónde está cada cosa.</th>
                                <td><input type="radio" name="pregunta25" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta25" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta25" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta25" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta25" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Cuando estoy en un examen, puedo “ver” la página en el libro de textos y la respuesta.</th>
                                <td><input type="radio" name="pregunta26" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta26" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta26" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta26" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta26" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">No puedo recordar una broma lo suficiente para contarla luego.</th>
                                <td><input type="radio" name="pregunta27" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta27" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta27" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta27" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta27" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Al aprender algo nuevo, prefiero escuchar la información, luego leer y luego hacerlo.</th>
                                <td><input type="radio" name="pregunta28" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta28" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta28" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta28" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta28" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Me gusta completar una tarea antes de comenzar otra.</th>
                                <td><input type="radio" name="pregunta29" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta29" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta29" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta29" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta29" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Uso mis dedos para contar y muevo los labios cuando leo.</th>
                                <td><input type="radio" name="pregunta30" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta30" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta30" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta30" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta30" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">No me gusta releer mi trabajo.</th>
                                <td><input type="radio" name="pregunta31" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta31" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta31" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta31" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta31" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Cuando estoy tratando de recordar algo nuevo, por ejemplo, un número de telefóno, me ayuda formarme una imagen mental para lograrlo.</th>
                                <td><input type="radio" name="pregunta32" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta32" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta32" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta32" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta32" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Para obtener una nota extra, prefiero grabar un informe a escribirlo.</th>
                                <td><input type="radio" name="pregunta33" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta33" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta33" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta33" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta33" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Fantaseo en clase.</th>
                                <td><input type="radio" name="pregunta34" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta34" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta34" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta34" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta34" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Para obtener una calificación extra, prefiero crear un proyecto a escribir un  informe.</th>
                                <td><input type="radio" name="pregunta35" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta35" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta35" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta35" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta35" id="pregunta" value="1" /></td>
                            </tr>
                            <tr>
                                <th scope="row">Cuando tengo una gran idea, debo escribirla inmediatamente, o la olvido con facilidad.</th>
                                <td><input type="radio" name="pregunta36" id="pregunta" value="5" /></td>
                                <td><input type="radio" name="pregunta36" id="pregunta" value="4" /></td>
                                <td><input type="radio" name="pregunta36" id="pregunta" value="3" /></td>
                                <td><input type="radio" name="pregunta36" id="pregunta" value="2" /></td>
                                <td><input type="radio" name="pregunta36" id="pregunta" value="1" /></td>
                            </tr>
                        </tbody>
                    </table>
                    <button className="btn-dark" onClick={obtenervalor}>Enviar</button>
                    <div className="wrapperResultados">
                        <div className="container_cartas">
                            <div className="card" style={{ width: "18rem" }}>
                                <div className="card-header">
                                    Puntaje
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li id="suma1" className="list-group-item"></li>
                                    <li id="suma2" className="list-group-item"></li>
                                    <li id="suma3" className="list-group-item"></li>
                                </ul>
                            </div>
                            <div className="card" style={{ width: "18rem" }}>
                                <div className="card-header">
                                    Porcentaje
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li id="suma4" className="list-group-item"></li>
                                    <li id="suma5" className="list-group-item"></li>
                                    <li id="suma6" className="list-group-item"></li>
                                </ul>
                            </div>

                        </div>

                        <div className="containerGrafica" id="containerGrafica">
                            <Doughnut data={stateValue} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Formulario;