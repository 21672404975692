import React, { useEffect, useRef, useState } from "react";
import Casita from "../img/contactocasita.png"
import $ from "jquery"
import "../css/Header.css";
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { AiOutlineMenu } from 'react-icons/ai';
import ModalReu from "../../Modal/ModalReu";

function Header() {

  useEffect(() => {
    var scrollLink = $('.scroll');

    $(window).scroll(function () {
      var scrollbarLocation = $(this).scrollTop();

      scrollLink.each(function () {

        var hash = this.hash;
        if (hash) {
          var sectionOffset = $(this.hash).offset().top - 20;
        }
        if (sectionOffset <= scrollbarLocation) {
          $(this).addClass('activenav');
          $(this).siblings().removeClass('activenav');

        }
      })
    })
  }, []);

  const Barrita = useRef()

  useEffect(() => {
    $(document).on("scroll", function () {
      var p = $(Barrita.current).last();
      var offset = p.offset();
      if (offset.top === 0) {
        $(".containerheader").removeClass('barra');

      } else {
        $(".containerheader").addClass('barra');
      }
    });
  }, [])

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [estadoRecorrido, cambiarestadoRecorrido] = useState(false);


  const logo = require(`../img/contactoLogo.png`)
  return (
    <>
      <ModalReu
        estado={estadoRecorrido}
        cambiarEstado={cambiarestadoRecorrido}


      >
        <iframe className="recorridoVirtual" width="100%" height="100%" allowvr="yes" allow="xr-spatial-tracking" allowfullscreen="yes" frameborder="0" src="https://orbix360.com/t/L4BTjWE3UHWwbQRgAeaeVUCyhvS2/5475204272750592/recorrido-virtual-ucp?embed=yes"></iframe>      </ModalReu>

      <div className="container_nav">
        <div ref={Barrita} className="containerheader">
          <i className="material-icons">
            <AiOutlineMenu onClick={handleShow} ></AiOutlineMenu>
          </i>

          <div className="contenidoNav">
            <HashLink smooth to="/#inicio" className="nav-link scroll ">
              <img src={Casita} id="casita" alt="banner" />
            </HashLink>


            <HashLink smooth to="/#servicios_virtuales" className="nav-link scroll">Servicios virtuales</HashLink>


            <HashLink smooth to="/#oferta_virtual" className="nav-link scroll"> Oferta virtual</HashLink>

            <HashLink className="nav-link scroll" onClick={() => cambiarestadoRecorrido(!estadoRecorrido)}> Recorrido 360°</HashLink>

            <a className="nav-link" target={"_blank"} rel="noreferrer" href="https://miaulavirtual.ucp.edu.co/">
              Moodle
            </a>

            <a className="nav-link" target={"_blank"} rel="noreferrer" href="https://tiendadelau.ucp.edu.co/">
              Tienda de la U
            </a>

            <a className="nav-link" target={"_blank"} rel="noreferrer" href="https://www.ucp.edu.co/apps/vamdate/">
              Recepción virtual
            </a>
          </div>
          <div className="imagen">
            <img onClick={Logoir} src={logo} alt="" style={{ cursor: "pointer" }} />
          </div>
        </div>

      </div>


      <Offcanvas show={show} onHide={handleClose} scroll={true}>
        <Offcanvas.Body>

          <ul id="slide-out" className="sidenav deco">
            <li className="li_background" style={{ marginTop: "80px" }}>


              <i className="bi bi-house-door"></i>
              <HashLink smooth to="#inicio" className="nav-link scroll ">
                Inicio
              </HashLink>
            </li>
            <li className="nav-item">
              <i className="bi bi-box2-heart" style={{ color: "#77383E" }}></i>
              <HashLink smooth to="/#servicio_virtual" className="nav-link scroll">Servicios virtuales</HashLink>

            </li>
            <li className="nav-item">
              <i className="bi bi-calendar-event" style={{ color: "#77383E" }}></i><a onClick={() => cambiarestadoRecorrido(!estadoRecorrido)} className="nav-link sliderNav">
                Recorrido 360°
              </a>
            </li>
            <li className="nav-item">
              <i className="bi bi-clipboard" style={{ color: "#77383E" }}></i><a className="nav-link sliderNav" target={"_blank"} rel="noreferrer" href="https://miaulavirtual.ucp.edu.co/">
                Moodle
              </a>
            </li>

            <li className="nav-item">
              <i className="bi bi-shop" style={{ color: "#77383E" }}></i><a className="nav-link sliderNav" target={"_blank"} rel="noreferrer" href="https://tiendadelau.ucp.edu.co/">
                Tienda de la U
              </a>
            </li>

            <li className="nav-item">
              <i className="bi bi-chat" style={{ color: "#77383E" }}></i><a className="nav-link sliderNav" target={"_blank"} rel="noreferrer" href="https://www.ucp.edu.co/apps/vamdate/">
                Recepción Virtual
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Header;

function Logoir() {
  window.open("https://www.ucp.edu.co/", "_blank");
}