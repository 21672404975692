import React from "react";
import "../css/Contactos.css"
const Logo = require(`../img/contactoLogo.png`)

function Contactos() {
  return (
    <div className="container_contacto_fijos">
      <div className="img">
        <img src={Logo} alt="" />
      </div>
      <div className="contactos_items">
        <ul>
          <li>
            <i className="bi bi-geo-alt"></i>
            <span>Avenida de las Américas No. 49-95 Pereira

            </span>
          </li>
          <li>
            <i className="bi bi-telephone-fill"></i
            ><span>PBX: (+57)(6) 312 4000</span>
          </li>
          <li>Centro de Innovación Educativa</li>
          <li>
            <i className="bi bi-envelope-fill"></i><span>cie@ucp.edu.co</span>
          </li>
          <li>
            <i className="bi bi-telephone-fill"></i
            ><span>ext. 1033 - 1113 - 1114 </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Contactos;