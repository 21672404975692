import React from "react";
import $ from "jquery";
import "../css/Ayuda.css";
function Ayuda() {

  function Grises() {
    var selection = document.querySelector("div.active-gray") !== null;
    if (selection) {
      const matches = document.querySelectorAll("div#PrincipalUniversidad, img, .containerheader, .ContainerSliderAyuda, .NombreCambiante, .modal-body i");
      $("#Grises").css("background-color", "transparent");
      $(matches).removeClass("active-gray");



    } else {
      const matches = document.querySelectorAll("div#PrincipalUniversidad, img, .containerheader, .ContainerSliderAyuda, .NombreCambiante, .modal-body i");
      $("#Grises").css("background-color", "white");
      $(matches).addClass("active-gray");
      $(matches).removeClass("active-saturacion");
      $(matches).removeClass("active-contrast");
    }
  }


  function Contraste() {
    var selection2 = document.querySelector("div.active-contrast") !== null;
    if (selection2) {
      const matches = document.querySelectorAll("div#PrincipalUniversidad, img,  .containerheader, .ContainerSliderAyuda, .NombreCambiante, .modal-body i");

      $("#Contrast").css("background-color", "transparent");
      $(matches).removeClass("active-contrast");


    } else {
      const matches = document.querySelectorAll("div#PrincipalUniversidad, img, .containerheader, .ContainerSliderAyuda, .NombreCambiante, .modal-body i");
      console.log(matches);
      $("#Contrast").css("background-color", "white");
      $(matches).addClass("active-contrast");
      $(matches).removeClass("active-gray");
      $(matches).removeClass("active-saturacion");

    }
  }

  function Resaltar() {
    var selection2 = document.querySelector(".linea_active") !== null;
    console.log(selection2)
    if (selection2) {
      const matches = document.querySelectorAll("[href]");
      $(matches).removeClass("linea_active");

    } else {
      const matches = document.querySelectorAll("[href]");
      console.log(matches);
      $(matches).addClass("linea_active");

    }
  }

  function Subrayar() {

    var selection2 = document.querySelector(".linea_active2") !== null;
    console.log(selection2)
    if (selection2) {
      const matches = document.querySelectorAll("[href]");
      $(matches).removeClass("linea_active2");

    } else {
      const matches = document.querySelectorAll("[href]");
      console.log(matches);
      $(matches).addClass("linea_active2");

    }
  }

  function Cerrar() {
    $(".ContainerSliderAyuda").css("position", "fixed")

    setTimeout(() => { $(".ContainerSliderAyuda").toggle("slide", { direction: "right" }, 500); })
  }

  function Saturacion() {
    var selection2 = document.querySelector("div.active-saturacion") !== null;
    if (selection2) {
      const matches = document.querySelectorAll("div#PrincipalUniversidad, .containerheader,   .modal-body img, .ContainerSliderAyuda, .NombreCambiante, .modal-body i");

      $("#Saturast").css("background-color", "transparent");
      $(matches).removeClass("active-saturacion");


    } else {
      const matches = document.querySelectorAll("div#PrincipalUniversidad, .containerheader, .modal-body img, .ContainerSliderAyuda, .NombreCambiante, .modal-body i");
      console.log(matches);
      $("#Saturast").css("background-color", "white");
      $(matches).addClass("active-saturacion");
      $(matches).removeClass("active-contrast");
      $(matches).removeClass("active-gray");
    }
  }


  function fuente() {
    var selection2 = document.querySelector(".active-font") !== null;
    if (selection2) {
      const matches = document.querySelectorAll("p, a, h1, h2, h3, h4, h5, th, tr, thead, label, button");
      $(matches).removeClass("active-font");


    } else {
      const matches = document.querySelectorAll("p, a, h1, h2, h3, h4, h5, th, tr, thead, label, button");
      console.log(matches);
      $(matches).addClass("active-font");
    }
  }


  function obtenerTamano() {
    const matches = document.querySelectorAll("p, a, h1, h2, h3, h4, h5");
    matches.forEach(resultados => {
      var fontSize = window.getComputedStyle(resultados).fontSize;
      var newSize = parseFloat(fontSize) + 1;
      resultados.style.fontSize = newSize + 'px';
      console.log(newSize)
    })
  }


  function obtenerTamanoMenos() {
    const matches = document.querySelectorAll("p, a, h1, h2, h3, h4, h5");
    matches.forEach(resultados => {
      var fontSize = window.getComputedStyle(resultados).fontSize;
      var newSize = parseFloat(fontSize) - 1;
      resultados.style.fontSize = newSize + 'px';
      console.log(newSize)
    })
  }
  return (
    <div className="containerAyuda position-sticky">
      <div className="ContainerSliderAyuda">
        <h5 className="accesibilidad">Herramientas de accesibilidad</h5>
        <div className="fontsize">
          <button className="fontmax" id="fontmax" onClick={obtenerTamano}>A+</button>
          <button className="fontmin" id="fontmin" onClick={obtenerTamanoMenos}>A-</button>
        </div>
        <button onClick={fuente} > <i className="bi bi-file-font"></i>
          Fuente Legible </button>
        <button onClick={Subrayar}><i className="bi bi-pencil"></i>
          Subrayar Enlaces</button>
        <button onClick={Resaltar}><i className="bi bi-pencil"></i>
          Resaltar Enlaces</button>
        <button id="Grises" className="grises2" onClick={Grises}>
          <i className="bi bi-eyedropper"></i>
          Escala de Grises
        </button>
        <button id="Contrast" className="contrast2" onClick={Contraste}>
          <i className="bi bi-brightness-high"></i>


          Contraste
        </button>
        <button id="Saturast" className="saturacion2" onClick={Saturacion}>
          <i className="bi bi-eyedropper"></i>
          Invertir
        </button>
        <button className="cerrar" onClick={Cerrar}>Cerrar</button>

      </div>
    </div>
  );
}

export default Ayuda;
