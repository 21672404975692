import { React } from "react";
import "../css/Inicio.css";
import Video from "../img/U--Virtual.webm"

function Inicio() {




  return (
    <div className="container_inicio">
      <video loop muted autoPlay width={"100%"}>
        <source src={Video} type="video/webm" />

      </video>

      <div className="redes_sociales">
        <a target={"_blank"} rel="noreferrer" href="https://www.facebook.com/ucatolicadepereira"><i className="bi bi-facebook m-1"></i></a>
        <a target={"_blank"} rel="noreferrer" href="https://twitter.com/UCatolicaPei"><i className="bi bi-twitter m-1"></i></a>
        <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/ucatolicadepereira/"><i className="bi bi-instagram m-1"></i></a>
        <a target={"_blank"} rel="noreferrer" href="https://www.youtube.com/user/CanalUCP"><i className="bi bi-youtube m-1"></i></a>
        <a target={"_blank"} rel="noreferrer" href="https://www.linkedin.com/school/ucatolicadepereira/mycompany/"><i className="bi bi-linkedin m-1"></i></a>
      </div>

    <h1>UVirtual</h1>

    </div>

  );
}

export default Inicio;
