import React from "react";
import "../css/Personas.css";


function Personas({children}) {
 
  return (
    <div>
      <li className="ml-2">
        {children}
      </li>
    </div>
  );
}

export default Personas;
