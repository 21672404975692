import React from "react";
import "../css/Footer.css"
import imagen2 from "../../Modal/img/Ale1.png"

function Footer() {
  return (
    <>
      <footer>
        <p>&#169; Universidad Católica de Pereira</p>
        <p>
          Institución de Educación Superior sujeta a inspección y vigilancia por parte del Ministerio de Educación Nacional. <span onClick={irResolucion} style={{ textDecoration: "underline", cursor: "pointer" }}>Resolución 10918</span> por la cual se reconoce la persona jurídica        </p>
      </footer>

    
    </>
  )
}

export default Footer

function irResolucion() {
  var myWindow = window.open("https://www.ucp.edu.co/portal/wp-content/uploads/2023/03/personeria-juridica-20160709.pdf", "_blank");
}