import React, { useEffect } from "react";
import styled from "styled-components";

function ModalReu({ children, estado, cambiarEstado }) {
    useEffect(() => {


        if (estado) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [estado]);

    return (

        <>
            {estado &&
                <Overlay id="OverlayWrapper" onClick={() => cambiarEstado(!estado)}>
                    <ContenedorModal id="ModalWrapper">

                        <Contenido>
                            {children}
                        </Contenido>

                    </ContenedorModal>

                </Overlay>
            }
        </>

    );
}



export default ModalReu;

const Overlay = styled.div`
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 9999;
            background-color: rgba(0,0,0, 0.5)
            `;

const ContenedorModal = styled.div`
            width: 75vw;
            height: 90vh;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 801px) {
                height: 400px;
                width: 90vw;

  }

  @media (min-width: 802px) and (max-width: 1000px) {
    width: 90vw;

}
            `

const EncabezadoModal = styled.div`
            display: flex;
            align.items: center;
            justify-content: space-between;
            height: 50px;
            background: transparent;
            h3{
                font - weight: 500;     
    }

            `

const BottonCerrar = styled.button`
            position: absolute;
            top: 20px;
            right: 0;
            width: 30px;
            height: 30px;
            border: none;
            background:  white;
            cursor: pointer;
            transition: .3s ease all;
            color: #1766d6;
            font-size: 20px;

            &:hover{
                background: #f2f2f2;
    }
            `

const Contenido = styled.div`
            height: 100%;
            display: contents;

            @media (max-width: 1000px) {
                iframe{
                    height: 100% !important;
                    position: relative;
                }

                
            }

            iframe{
                height: 90% ;
                position: relative;
            }

            `