import React from "react";
import Header from "./Header";

function Error() {

  return (
    <>
      <div style={{ height: "80px", backgroundColor: "#77383E" }}>
        <Header />
      </div>
      <div id="error-page" style={{ height: "80vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <h1>Oops!</h1>
        <p>Lo siento, ha ocurrido un error</p>
        <p>
          <strong>Página no encontrada</strong>
        </p>
      </div>
    </>
  );
}

export default Error;