import React from "react";

function Modal() {

  const logo = require(`./img/pngwing.com.png`)
  return (

    <div
      className="modal fade"
      id="exampleModal1"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ backgroundColor: "transparent" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              RECTORIA VIRTUAL
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="modal-items">
              <div className="texto">
                <h3>¿Sabías que puedes programar una cita con el Rector de la Universiad?</h3>
                <p>Ingresa al formulatio de Google compartidp por la Universidad, digita los datos y en el transcurso del dia te enviaremos un correo con la cita programada y el enlace  para ingresar a ella segun la fecha y hora establecida</p>
                <button>Agenda tu cita</button>
              </div>
              <div className="imagen_modal">
                <img loading="lazy" alt="" src={logo} />
              </div>
            </div>
            
          </div>
          <div className="modal-footer">
          <div className="wave_modal" style={{height: "150px", overflow: "hidden", width: "100%"}}><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{height: "100%", width: "100%"}}><path d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z" style={{stroke: "none", fill: "#d5ab33" , width: "100%"}}></path></svg></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
