import React from "react";
import { useState, useEffect } from "react";
import { TbSquareNumber1, TbSquareNumber2, TbSquareNumber3, TbSquareNumber4 } from 'react-icons/tb';
import Carousel from 'react-bootstrap/Carousel';
import styled from "styled-components";
import "./css/ModalMapa.css"

function MapaModal() {
  const kabai1 = require("./img/Kabai1er.png")
  const kabai2 = require("./img/Kabai2do.png")
  const kabai3 = require("./img/kabai_3.png")
  const buena1 = require("./img/BuenaNuevaPlanta1.png")
  const buena2 = require("./img/BuenanuevaPlanta2.png")
  const medios2 = require("./img/medios2.png")
  const cancha = require("./img/Fotos/Cancha.jpg")
  const cancha2 = require("./img/Fotos/volley.jpg")
  const cancha3 = require("./img/Fotos/multiple.jpg")

  const medios2dopiso = require("./img/2dopiso.jpg")

  const dabar1 = require("./img/dabar1er.png")
  const dabar2 = require("./img/Dabar2.png")
  const dabar3 = require("./img/Dabar3erpiso.png")
  const capsi = require("./img/capsi.png")
  const humanitas1 = require("./img/Humanitaspiso1.png")
  const humanitas2 = require("./img/Humnitaspiso2.png")
  const aletheian1 = require("./img/Ale1.png")
  const aletheian2 = require("./img/Ale2.png")
  const aletheian3 = require("./img/Ale3.png")
  const biblioteca1 = require("./img/Bibliotecapiso1.png")
  const biblioteca2 = require("./img/Bibliotecapiso2.png")
  const biblioteca3 = require("./img/Bibliotecapiso3.png")
  const biblioteca4 = require("./img/Bibliotecapiso4.png")
  const posgrados1 = require("./img/posgrados1.png")
  const posgrados2 = require("./img/posgrados2.png")
  const posgrados3 = require("./img/posgrados3.png")
  const entradap = require("./img/Recepcion.png")
  const entradan = require("./img/Caseta.png")
  const plazoleta = require("./img/Plazoleta.png")
  const bkabai = require("./img/Fotos/BKabai.jpg")


  const humanitas_capilla = require("./img/Fotos/Capilla.png")
  const humanitas_gestionh = require("./img/Fotos/Gestion.png")
  const humanitas_practicas = require("./img/Fotos/Practicas.png")
  const humanitas_patio = require("./img/Fotos/patio.png")
  const humanitas_entrada = require("./img/Fotos/Entrada.png")
  const humanitas_estudiantes = require("./img/Fotos/salaestudiante.jpg")
  const Salones = require("./img/Fotos/Salones.png")


  const humanitas2_campus = require("./img/Fotos/Campus.png")
  const humanitas2_ciuc = require("./img/Fotos/CIUC.png")
  const humanitas2_comun = require("./img/Fotos/Comun.png")
  const humanitas2_psicologia = require("./img/Fotos/Psicologia.png")
  const humanitas2_archivo = require("./img/Fotos/archivo.jpg")

  const humanitas1_planeacion = require("./img/Fotos/planeacion.jpg")
  const humanitas1_baños = require("./img/Fotos/bañoshumanitas.jpg")
  const humanitas1_salones2 = require("./img/Fotos/sala109.jpg")
  const humanitas2_fhcse = require("./img/Fotos/fhcse.jpg")

  const humanitas1_almacen = require("./img/Fotos/almacen.jpg")
  const ale1_mercadeo = require("./img/Fotos/Mercadeo.png")
  const ale1_tienda = require("./img/Fotos/CafeteriaR.png")
  const ale1_cafeteria = require("./img/Fotos/CafeteriaRoja.png")
  const ale1_banco = require("./img/Fotos/AvVillas.png")

  const banderas = require("./img/Fotos/banderas.jpg")


  const bn1_Oval1 = require("./img/Fotos/Oval1.png")
  const bn1_Talleri = require("./img/Fotos/Taller_industrial.png")
  const bn1_Centrom = require("./img/Fotos/Centro.png")
  const bn1_Prodilab = require("./img/Fotos/Prodilab.png")
  const bn1_cubi = require("./img/Fotos/cubiculoscapsi.jpg")

  const capsi_capsi = require("./img/Fotos/capsi.png")
  const capsi_enfermeria = require("./img/Fotos/Enfermeria.png")

  const dabar1_auditorio = require("./img/Fotos/AuditorioDabar.png")
  const dabar1_centrof = require("./img/Fotos/CentroF.png")
  const dabar1_pb = require("./img/Fotos/PB.png")
  const dabar1_salondabar1 = require("./img/Fotos/salondabar1.jpg")
  const dabar1_salaconsejos = require("./img/Fotos/SalaConsejos.jpg")


  const dabar2_oval2 = require("./img/Fotos/Oval2.jpg")
  const dabar2_centrocultura = require("./img/Fotos/CentroActividadesCulturales.png")
  const dabar2_salonesd2 = require("./img/Fotos/salondabar2.jpg")
  const dabar2_cubicuos = require("./img/Fotos/CubiculosCA.jpg")
  const dabar2_gradas = require("./img/Fotos/Gradas.jpg")
  const dabar2_salamultiple = require("./img/Fotos/salonmultiple.jpg")
  const dabar2_lab_ii = require("./img/Fotos/Labii.jpg")
  const dabar2_lab_fisica = require("./img/Fotos/LabFisica.jpg")
  const dabar2_tatrino = require("./img/Fotos/Teatrino.jpg")
  const dabar2_ingsis = require("./img/Fotos/direccióncienciasbásicas.jpg")

  const dabar3_salones = require("./img/Fotos/SD3.png")
  const dabar3_oval3 = require("./img/Fotos/Oval3.jpg")
  const dabar3_salamac = require("./img/Fotos/SalaMac.jpg")

  const kabai1_cafeteriazul = require("./img/Fotos/Cafeteriaa.png")
  const kabai1_baños = require("./img/Fotos/bañokabai1.jpg")

  const kabai1_programamer = require("./img/Fotos/ProgramaMER.png")
  const kabai1_fotocopiadora = require("./img/Fotos/Fotocopiadora.png")
  const kabai1_salones = require("./img/Fotos/salonesk1.png")
  const kabai1_papeleria = require("./img/Fotos/Papelería.jpg")
  const kabai1_pv = require("./img/Fotos/Proyectodevida.jpg")

  const kabai2_salones = require("./img/Fotos/salonesk2.jpg")
  const kabai2_baños = require("./img/Fotos/Bañosk2.jpg")
  const kabai2_labpsi = require("./img/Fotos/LabPsi.jpg")
  const kabai2_labneu = require("./img/Fotos/LabNeuro.jpg")
  const kabai2_programaudio = require("./img/Fotos/ProgramaAudivisual.jpg")
  const kabai2_acuario = require("./img/Fotos/Acuario.jpg")
  const kabai2_dce = require("./img/Fotos/DCEA.jpg")
  const kabai2_inter = require("./img/Fotos/Internacionalización.jpg")

  const kabai3_salones = require("./img/Fotos/aula2.jpg")
  const kabai3_salones2 = require("./img/Fotos/salonesk3.jpg")
  const kabai3_cie = require("./img/Fotos/cie.jpg")
  const kabai3_plataforma = require("./img/Fotos/plataforma.png")
  const kabai3_emprendimiento = require("./img/Fotos/emprendimiento.jpg")
  const kabai3_visualizacion = require("./img/Fotos/LabVisualizacion.jpg")
  const kabai3_cubicie = require("./img/Fotos/cubicie.jpg")
  const kabai3_bug = require("./img/Fotos/Bigdata.jpg")
  const kabai3_av1 = require("./img/Fotos/Aulav1.jpg")
  const kabai3_vip = require("./img/Fotos/salavip.jpg")

  const ale2_teso = require("./img/Fotos/tesoreria.png")
  const ale2_finan = require("./img/Fotos/gestionf.png")
  const ale2_recto = require("./img/Fotos/rectoria.jpg")
  const ale2_admisiones = require("./img/Fotos/admr.jpg")
  const ale2_vice = require("./img/Fotos/viceacademica.jpg")
  const ale2_salap = require("./img/Fotos/salaprofe.jpg")
  const ale2_pastoral = require("./img/Fotos/pastoral.jpg")
  const ale1_baños = require("./img/Fotos/bañoaletheia.jpg");

  const ale3_gt = require("./img/Fotos/gt.png")
  const ale3_sc1 = require("./img/Fotos/sc2.png")
  const ale3_sc2 = require("./img/Fotos/sc1.png")
  const ale3_financiera = require("./img/Fotos/Financiera2.jpg")
  const ale3_fcbi = require("./img/Fotos/decanaturacienciasbásicaseingenieria.jpg")

  const bloque_bn = require("./img/Fotos/BBN.png")
  const bloque_d = require("./img/Fotos/BD.png")
  const bloque_k = require("./img/Fotos/sc2.png")
  const bloque_h = require("./img/Fotos/BH.png")
  const bloque_bb = require("./img/Fotos/Biblioteca.png")
  const bloque_pos = require("./img/Fotos/gt.png")
  const bloque_a = require("./img/Fotos/BAletheia.png")

  const biblioteca1_bibliocafe = require("./img/Fotos/Bibliocafe.jpg")
  const biblioteca1_infantil = require("./img/Fotos/salainfantil.jpg")
  const biblioteca1_dinv = require("./img/Fotos/DireccionInv.jpg")
  const biblioteca1_referencia = require("./img/Fotos/referencia.jpg")

  const biblioteca2_hemeroteca = require("./img/Fotos/Hemeroteca.jpg")
  const biblioteca2_circulacion = require("./img/Fotos/Circulacion.jpg")

  const biblioteca3_salagrupal = require("./img/Fotos/salagrupal.jpg")
  const biblioteca3_salap = require("./img/Fotos/salaprofeb.jpg")
  const biblioteca3_salageneral = require("./img/Fotos/salageneral.jpg")
  const biblioteca3_cubiculos = require("./img/Fotos/Cubiprofe.jpg")

  const biblioteca4_pinacoteca = require("./img/Fotos/Pinacoteca.jpg")

  const posgrados1_salones = require("./img/Fotos/Salonesposgrados1.jpg")
  const posgrados2_LER = require("./img/Fotos/LERPos.png")

  const posgrados3_oficina = require("./img/Fotos/OficinasPos3.jpg")

  const bn2_gym = require("./img/Fotos/gym.jpg")
  const bn2_salones = require("./img/Fotos/Bn2salon.jpg")

  const radio_radio = require("./img/Fotos/Radio.jpg")
  const radio_salon = require("./img/Fotos/Salon2piso.jpg")


  const parqueadero_motos = require("./img/Fotos/parqueaderomoto.jpg")
  const parqueadero_adm = require("./img/Fotos/parqueaderadm.JPG")
  const parqueadero_pri = require("./img/Fotos/parqueaderoauto.jpeg")
  const rioconsota = require("./img/Fotos/rioconsotaa.jpg")



  const [imagen, setImagen] = useState(() => {
    const guardado = localStorage.getItem("dataImagen");
    const initialValue = JSON.parse(guardado);
    return initialValue || "";
  });

  useEffect(() => {
    localStorage.setItem("dataImagen", JSON.stringify(imagen));
  });

  const [texto, setTexto] = useState("Espacios")

  $(document).ready(function () {

    $(".auditorio").hover(function () {
      setImagen(dabar1_auditorio)
      setTexto("Auditorio Dabar")
    });

    $(".acuario").hover(function () {
      $(".lista_kabai").css("visibility", "visible");
    }, function () {
      $(".lista_kabai").css("visibility", "hidden");
    });
  });

  $(document).ready(function () {
    $(".practicas").hover(function () {
      setImagen(humanitas_practicas)
      setTexto("Prácticas y Graduados")
    });

    $(".cie").hover(function () {
      setImagen(kabai3_cie)
      setTexto("CIE")
    });

    $(".ciesalones").hover(function () {
      setImagen(kabai3_salones)
      setTexto("Aula virtual y módulos")
    });
  });

  $(document).ready(function () {
    $(".capilla").hover(function () {
      setImagen(humanitas_capilla)
      setTexto("Capilla: San José ")

    });

    $(".hsalones").hover(function () {
      setImagen(Salones)
      setTexto("Salones")

    });

    $(".cubiculos").hover(function () {
      setImagen(kabai3_cubicie)
      setTexto("Cubículos de estudio")

    });


    $(".bibliocafe").hover(function () {
      setImagen(biblioteca1_bibliocafe)
      setTexto("Bibliocafé")

    });

    $(".salones2").hover(function () {
      setImagen(humanitas1_salones2)
      setTexto("Salones")

    });

    $(".infantil").hover(function () {
      setImagen(biblioteca1_infantil)
      setTexto("Sala infantil")

    });

    $(".consejis").hover(function () {
      setImagen(biblioteca1_infantil)
      setTexto("Sala de consejos")

    });

    $(".dinv").hover(function () {
      setImagen(biblioteca1_dinv)
      setTexto("Dirección de Investigación e Innovación")

    });

    $(".hfche").hover(function () {
      setImagen(humanitas2_fhcse)
      setTexto("Facultad de Ciencias Humanas, Sociales y de la Educación")
    });

    $(".piso3posgrados").hover(function () {
      setImagen(posgrados3_oficina)
      setTexto("Oficinas")

    });

    $(".referencia").hover(function () {
      setImagen(biblioteca1_referencia)
      setTexto("Referencia")

    });
  });

  $(".archivo").hover(function () {
    setImagen(humanitas2_archivo)
    setTexto("Archivo")

  });

  $(".bañosale").hover(function () {
    setImagen(ale1_baños)
    setTexto("Baños")

  });

  $(document).ready(function () {
    $(".gestionh").hover(function () {
      setImagen(humanitas_gestionh)
      setTexto("Gestión Humana")

    });

    $(".oval2").hover(function () {
      setImagen(dabar2_oval2)
      setTexto("Oval 2")

    });



    $(".financiera2").hover(function () {
      setImagen(ale3_financiera)
      setTexto("Gestión Financiera")

    });

    $(".consejos").hover(function () {
      setImagen(dabar1_salaconsejos)
      setTexto("Sala de consejos")

    });

    $(".ingsis").hover(function () {
      setImagen(dabar2_ingsis)
      setTexto("Dirección de IST, Dirección del Departamento de Ciencias Básicas")

    });

    $(".cubicapsi").hover(function () {
      setImagen(bn1_cubi)
      setTexto("Cubículos CAPSI")

    });
    $(".centrocultura").hover(function () {
      setImagen(dabar2_centrocultura)
      setTexto("Centro de Actividades Culturales")

    });

    $(".salonesd2").hover(function () {
      setImagen(dabar2_salonesd2)
      setTexto("Salones")

    });

    $(".teatrino").hover(function () {
      setImagen(dabar2_tatrino)
      setTexto("Teatrino")

    });

    $(".oval3").hover(function () {
      setImagen(dabar3_oval3)
      setTexto("Oval 3")

    });

    $(".cubiculosdabar").hover(function () {
      setImagen(dabar2_cubicuos)
      setTexto("Cubículos insonorizados")

    });

    $(".gradas").hover(function () {
      setImagen(dabar2_gradas)
      setTexto("Teatro al aire libre")
    });

    $(".salamultiple").hover(function () {
      setImagen(dabar2_salamultiple)
      setTexto("Sala múltiple")

    });

    $(".lab_fisica").hover(function () {
      setImagen(dabar2_lab_fisica)
      setTexto("Laboratorio de física")

    });


    $(".almacen").hover(function () {
      setImagen(humanitas1_almacen)
      setTexto("Almacén")

    });


    $(".dyp").hover(function () {
      setImagen(humanitas1_planeacion)
      setTexto("Dirección de Planeación y Calidad")

    });

    $(".labii").hover(function () {
      setImagen(dabar2_lab_ii)
      setTexto("Laboratorio de ingeniería industrial")

    });
  });

  $(document).ready(function () {
    $(".estudiantes").hover(function () {
      setImagen(humanitas_entrada)
      setTexto("Entrada Sala del Estudiante")
    });

    $(".patio").hover(function () {
      setImagen(humanitas_patio)
      setTexto("Patio interior")
    });


    $(".coorpc").hover(function () {
      setImagen(posgrados3_oficina)
      setTexto("Coordinación de Especialización en Psicología Clínica")
    });

    $(".coorcgh").hover(function () {
      setImagen(posgrados3_oficina)
      setTexto("Coordinación de Especialización en Gestión Humana de las Organizaciones")
    });

    $(".coorgpi").hover(function () {
      setImagen(posgrados3_oficina)
      setTexto("Coordinación de Especialización en Gestión de Proyectos de Innovación")
    });

    $(".cooredumatica").hover(function () {
      setImagen(posgrados3_oficina)
      setTexto("Coordinación de Especialización en Edumática")
    });

    $(".coorpsisocial").hover(function () {
      setImagen(posgrados3_oficina)
      setTexto("Coordinación de Especialización en Psicología Social Comunitaria y Acción Psicosocial")
    });


    $(".coorcgh").hover(function () {
      setImagen(posgrados3_oficina)
      setTexto("Coordinador de Comunicación y Gestión Humana")
    });

    $(".estudiante").hover(function () {
      setImagen(humanitas_estudiantes)
      setTexto("Sala del Estudiante")
    });

    $(".ciuc").hover(function () {
      setImagen(humanitas2_ciuc)
      setTexto("Proyección Social y Centro de Idiomas")
    });

    $(".comunicaciones").hover(function () {
      setImagen(humanitas2_comun)
      setTexto("Coordinación de Comunicaciones")
    });

    $(".psicologia").hover(function () {
      setImagen(humanitas2_psicologia)
      setTexto("Dirección de Psicología")
    });

    $(".direccionadm").hover(function () {
      setImagen(humanitas2_campus)
      setTexto("Dirección Administrativa y Financiera")
    });

    $(".tiendau").hover(function () {
      setImagen(ale1_tienda)
      setTexto("Tienda universitaria")
    });

    $(".cafeteriaroja").hover(function () {
      setImagen(ale1_cafeteria)
      setTexto("Cafetería roja")
    });

    $(".gmercadeo").hover(function () {
      setImagen(ale1_mercadeo)
      setTexto("Gestión de Mercadeo")
    });

    $(".salonesd1").hover(function () {
      setImagen(dabar1_salondabar1)
      setTexto("Salones")
    });

    $(".prodilab").hover(function () {
      setImagen(bn1_Prodilab)
      setTexto("Prodilab")
    });

    $(".arquitectura").hover(function () {
      setImagen(bn1_Talleri)
      setTexto("Taller de diseño industrial")
    });

    $(".medio").hover(function () {
      setImagen(bn1_Centrom)
      setTexto("Centro de medios")
    });

    $(".oval1").hover(function () {
      setImagen(bn1_Oval1)
      setTexto("Oval 1")
    });

    $(".enfermeria").hover(function () {
      setImagen(capsi_enfermeria)
      setTexto("Enfermería")
    });

    $(".capsi").hover(function () {
      setImagen(capsi_capsi)
      setTexto("CAPSI")
    });

    $(".banco").hover(function () {
      setImagen(ale1_banco)
      setTexto("Banco AV Villas")
    });

    $(".salonesk3").hover(function () {
      setImagen(kabai3_salones2)
      setTexto("Salones")
    });

    $(".prestamos").hover(function () {
      setImagen(biblioteca2_circulacion)
      setTexto("Circulación y préstamos")
    });

    $(".emeroteca").hover(function () {
      setImagen(biblioteca2_hemeroteca)
      setTexto("Hemeroteca")
    });



    $(".vrp").hover(function () {
      setImagen(dabar1_centrof)
      setTexto("Centro de Familia")
    });

    $(".piso2").hover(function () {
      setImagen(medios2dopiso)
      setTexto("Segundo piso centro de medios")
    });

    $(".pb").hover(function () {
      setImagen(dabar1_pb)
      setTexto("Punto de Bolsa")
    });

    $(".salonesbuena2").hover(function () {
      setImagen(bn2_salones)
      setTexto("Taller de diseño industrial")
    });

    $(".gym").hover(function () {
      setImagen(bn2_gym)
      setTexto("Gimnasio")
    });

    $(".foto").hover(function () {
      setImagen(kabai1_fotocopiadora)
      setTexto("Fotocopiadora")
    });

    $(".cafeteria").hover(function () {
      setImagen(kabai1_cafeteriazul)
      setTexto("Cafetería azul")
    });

    $(".salones").hover(function () {
      setImagen(kabai1_salones)
      setTexto("Salones")
    });

    $(".mercadeo").hover(function () {
      setImagen(kabai1_programamer)
      setTexto("Dirección de Programa de Mercadeo")
    });

    $(".salonmedio2").hover(function () {
      setImagen(radio_salon)
      setTexto("Salón")
    });

    $(".radio").hover(function () {
      setImagen(radio_radio)
      setTexto("Radio UCP")
    });

    $(".financiera").hover(function () {
      setImagen(ale2_finan)
      setTexto("Gestión Financiera")
    });




    $(".secrehuma").hover(function () {
      setImagen(posgrados2_LER)
      setTexto("Secretaría Humanidades y LER")
    });

    $(".direccionhuma").hover(function () {
      setImagen(posgrados2_LER)
      setTexto("Dirección Humanidades")
    });

    $(".posgradopegaogia").hover(function () {
      setImagen(posgrados2_LER)
      setTexto("Posgrados Pedagogía y Desarrollo Humano")
    });

    $(".profesionalpeda").hover(function () {
      setImagen(posgrados2_LER)
      setTexto("Profesional de posgrados")
    });


    $(".direccionlic").hover(function () {
      setImagen(posgrados2_LER)
      setTexto("Dirección de Licenciatura de Educación Religiosa")
    });


    $(".tesoreria").hover(function () {
      setImagen(ale2_teso)
      setTexto("Tesorería")
    });

    $(".salaprofe").hover(function () {
      setImagen(ale2_salap)
      setTexto("Cubículos de profesores")
    });

    $(".vice").hover(function () {
      setImagen(ale2_vice)
      setTexto("Vicerrectoría Académica")
    });

    $(".rectoria").hover(function () {
      setImagen(ale2_recto)
      setTexto("Rectoría")
    });

    $(".fcbi").hover(function () {
      setImagen(ale3_fcbi)
      setTexto("Facultad de Ciencias Básicas e Ingeniería")
    });

    $(".admisiones").hover(function () {
      setImagen(ale2_admisiones)
      setTexto("Admisiones y Registro")
    });

    $(".sc1").hover(function () {
      setImagen(ale3_sc1)
      setTexto("Sala de sistemas")
    });


    $(".salag").hover(function () {
      setImagen(biblioteca3_salageneral)
      setTexto("Sala general")
    });


    $(".salap").hover(function () {
      setImagen(biblioteca3_salap)
      setTexto("Sala de profesores")
    });


    $(".salagrupal").hover(function () {
      setImagen(biblioteca3_salagrupal)
      setTexto("Sala grupal")
    });


    $(".cubiculosb").hover(function () {
      setImagen(biblioteca3_cubiculos)
      setTexto("Cubículos profesores")
    });
    $(".pinacoteca").hover(function () {
      setImagen(biblioteca4_pinacoteca)
      setTexto("Pinacoteca")
    });

    $(".salonesk2").hover(function () {
      setImagen(kabai2_salones)
      setTexto("Salones")
    });

    $(".lab_neuro").hover(function () {
      setImagen(kabai2_labneu)
      setTexto("Laboratorio de Neurociencia")
    });

    $(".bibliotecapiso2a").click(function () {
      setImagen(biblioteca2_circulacion)
      setTexto("Biblioteca")
    });

    $(".bibliotecapiso3a").click(function () {
      setImagen(biblioteca3_salageneral)
      setTexto("Biblioteca")
    });

    $(".bibliotecapisoa").click(function () {
      setImagen(biblioteca1_referencia)
      setTexto("Biblioteca")
    });


    $(".bibliotecapiso4a").click(function () {
      setImagen(biblioteca4_pinacoteca)
      setTexto("Biblioteca")
    });

    $(".lab_psi").hover(function () {
      setImagen(kabai2_labpsi)
      setTexto("Laboratorio de psicología")
    });

    $(".programa_DA").hover(function () {
      setImagen(ale3_fcbi)
      setTexto("Decanatura de Facultad de Ciencias Básicas e Ingeniería")
    });

    $(".baños").hover(function () {
      setImagen(kabai2_baños)
      setTexto("Baños")
    });

    $(".DCE").hover(function () {
      setImagen(kabai2_dce)
      setTexto("Decanatura de Facultad de Ciencias Económicas y Administrativas")
    });

    $(".inter").hover(function () {
      setImagen(kabai2_inter)
      setTexto("Internacionalización")
    });

    $(".acuario").hover(function () {
      setImagen(kabai2_acuario)
      setTexto("Oficinas")
    });


    $(".pastoral").hover(function () {
      setImagen(ale2_pastoral)
      setTexto("Pastoral Universitaria")
    });
    $(".gh").hover(function () {
      setImagen(ale3_gt)
      setTexto("Gestión Tecnológica")
    });

    $(".plataforma").hover(function () {
      setImagen(kabai3_plataforma)
      setTexto("Plataforma innovarte")
    });

    $(".emprendimiento").hover(function () {
      setImagen(kabai3_emprendimiento)
      setTexto("Sala de emprendimiento")
    });

    $(".laboratorio").hover(function () {
      setImagen(kabai3_visualizacion)
      setTexto("Laboratorio visualización avanzada")
    });

    $(".salonesd3").hover(function () {
      setImagen(dabar3_salones)
      setTexto("Salones")
    });

    $(".papeleria").hover(function () {
      setImagen(kabai1_papeleria)
      setTexto("Papelería")
    });

    $(".vrp2").hover(function () {
      setImagen(kabai1_pv)
      setTexto("Vicerrectoría Proyecto de Vida")
    });

    $("#geo_1").hover(function () {
      setImagen(bloque_bn)
      setTexto("Buena Nueva")
    });

    $("#buena_nuevabtn").hover(function () {
      setImagen(bloque_bn)
      setTexto("Buena Nueva")
    });

    $(".piso1posgrados").hover(function () {
      setImagen(posgrados1_salones)
      setTexto("Salones")
    });

    $(".piso2posgrados").hover(function () {
      setImagen(posgrados2_LER)
      setTexto("Dep. Humanidades y LER")
    });

    $("#geo_2").hover(function () {
      setImagen(bloque_d)
      setTexto("Dabar")
    });

    $("#dabarbtn").hover(function () {
      setImagen(bloque_d)
      setTexto("Dabar")
    });

    $("#geo_4").hover(function () {
      setImagen(bloque_a)
      setTexto(" Aletheia")
    });

    $("#aletheianbtn").hover(function () {
      setImagen(bloque_a)
      setTexto(" Aletheia")
    });

    $("#geo_5").hover(function () {
      setImagen(bloque_bb)
      setTexto("Biblioteca")
    });

    $("#bibliotecabtn").hover(function () {
      setImagen(bloque_bb)
      setTexto("Biblioteca")
    });

    $("#geo_6").hover(function () {
      setImagen(posgrados1_salones)
      setTexto("Posgrados")
    });

    $("#humanitasbtn").hover(function () {
      setImagen(posgrados1_salones)
      setTexto("osgrados")
    });

    $("#geo_7").hover(function () {
      setImagen(bloque_h)
      setTexto("Humanitas")
    });

    $("#posgradosbtn").hover(function () {
      setImagen(bloque_h)
      setTexto("Humanitas")
    });

    $("#geo_3").hover(function () {
      setImagen(bkabai)
      setTexto("Kabai")
    });

    $("#kabaibtn").hover(function () {
      setImagen(bkabai)
      setTexto("Kabai")
    });

    $(".salamac").hover(function () {
      setImagen(dabar3_salamac)
      setTexto("Sala mac")
    });

    $(".bigdata").hover(function () {
      setImagen(kabai3_bug)
      setTexto("Laboratorio Big Data")
    });

    $(".salvip").hover(function () {
      setImagen(kabai3_vip)
      setTexto("Sala VIP")
    });

    $(".aulav1").hover(function () {
      setImagen(kabai3_av1)
      setTexto("Aula virtual 1")
    });

    $(".bañoshumanitas").hover(function () {
      setImagen(humanitas1_baños)
      setTexto("Baños")
    });

    $(".bañoskabai1").hover(function () {
      setImagen(kabai1_baños)
      setTexto("Baños")
    });

  });

  const Buenair1 = () => {
    $("#Buena1").modal("show");
    $("#Buena2").modal("hide");
    $("#Capsi").modal("hide");
    setImagen(bn1_Centrom)

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");



  }

  const Buenair2 = () => {
    $("#Buena1").modal("hide");
    $("#Capsi").modal("show");
    $("#Buena2").modal("hide");
    setImagen(capsi_capsi)


    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");

  }

  const Buenair3 = () => {
    $("#Buena1").modal("hide");
    $("#Buena2").modal("show");
    $("#Capsi").modal("hide");
    setImagen(bn2_salones)


    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(3)").addClass("pisoactivo");

  }

  const DabarIr1 = () => {
    setImagen(bloque_d)
    setTexto("Dabar")
    $("#Dabar1").modal("show");
    $("#Dabar2").modal("hide");
    $("#Dabar3").modal("hide");


    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");

  }

  const DabarIr2 = () => {
    $("#Dabar1").modal("hide");
    $("#Dabar2").modal("show");
    $("#Dabar3").modal("hide");
    setImagen(dabar2_salonesd2)
    setTexto("Dabar")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");

  }

  const DabarIr3 = () => {
    $("#Dabar1").modal("hide");
    $("#Dabar2").modal("hide");
    $("#Dabar3").modal("show");
    setImagen(dabar3_salones)
    setTexto("Dabar")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(3)").addClass("pisoactivo");

  }

  const Kabair1 = () => {
    $("#Kabai1").modal("show");
    $("#Kabai2").modal("hide");
    $("#Kabai3").modal("hide");
    setImagen(kabai1_salones)
    setTexto("Kabai")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");

  }


  const Kabair2 = () => {
    $("#Kabai1").modal("hide");
    $("#Kabai2").modal("show");
    $("#Kabai3").modal("hide");
    setImagen(kabai2_salones)
    setTexto("Kabai")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");
  }



  const Kabair3 = () => {
    $("#Kabai1").modal("hide");
    $("#Kabai2").modal("hide");
    $("#Kabai3").modal("show");
    setImagen(kabai3_salones)
    setTexto("Kabai")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(3)").addClass("pisoactivo");

  }


  const AleIr1 = () => {
    $("#Ale1").modal("show");
    $("#Ale2").modal("hide");
    $("#Ale3").modal("hide");
    setImagen(ale1_cafeteria)
    setTexto("Aletheia")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");

  }

  const AleIr2 = () => {
    $("#Ale1").modal("hide");
    $("#Ale2").modal("show");
    $("#Ale3").modal("hide");
    setImagen(ale2_admisiones)
    setTexto("Aletheia")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");

  }

  const AleIr3 = () => {
    $("#Ale1").modal("hide");
    $("#Ale2").modal("hide");
    $("#Ale3").modal("show");
    setImagen(bloque_a)
    setTexto("Aletheia")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(3)").addClass("pisoactivo");

  }


  const BilbliotecaIr1 = () => {
    $("#Biblioteca1").modal("show");
    $("#Biblioteca2").modal("hide");
    $("#Biblioteca3").modal("hide");
    $("#Biblioteca4").modal("hide");


    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");

  }


  const BilbliotecaIr2 = () => {
    $("#Biblioteca1").modal("hide");
    $("#Biblioteca2").modal("show");
    $("#Biblioteca3").modal("hide");
    $("#Biblioteca4").modal("hide");


    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");

  }

  const BilbliotecaIr3 = () => {
    $("#Biblioteca1").modal("hide");
    $("#Biblioteca2").modal("hide");
    $("#Biblioteca3").modal("show");
    $("#Biblioteca4").modal("hide");


    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(3)").addClass("pisoactivo");

  }

  const BilbliotecaIr4 = () => {
    $("#Biblioteca1").modal("hide");
    $("#Biblioteca2").modal("hide");
    $("#Biblioteca3").modal("hide");
    $("#Biblioteca4").modal("show");


    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(4)").addClass("pisoactivo");

  }

  const HumanitasIr1 = () => {
    $("#Humanitas1").modal("show");
    $("#Humanitas2").modal("hide");
    setImagen(humanitas_patio)
    setTexto("Humanitas")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");

  }

  const HumanitasIr2 = () => {
    $("#Humanitas1").modal("hide");
    $("#Humanitas2").modal("show");
    setImagen(humanitas2_ciuc)
    setTexto("Humanitas")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");

  }


  const posgradosIr1 = () => {
    $("#Posgrados1").modal("show");
    $("#Posgrados2").modal("hide");
    $("#Posgrados3").modal("hide");
    setImagen(posgrados1_salones)
    setTexto("Posgrados")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");


  }

  const posgradosIr2 = () => {
    $("#Posgrados1").modal("hide");
    $("#Posgrados2").modal("show");
    $("#Posgrados3").modal("hide");
    setImagen(posgrados2_LER)
    setTexto("Posgrados")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");

  }

  const posgradosIr3 = () => {
    $("#Posgrados1").modal("hide");
    $("#Posgrados2").modal("hide");
    $("#Posgrados3").modal("show");
    setImagen(posgrados3_oficina)
    setTexto("Posgrados")

    const matches = document.querySelectorAll("div.pisoactivo");
    $(matches).removeClass("pisoactivo");
    $(".iconosPisos div:nth-child(3)").addClass("pisoactivo");

  }


  return (
    <div>
      <div className="modal modal_mapa fade" id="Kabai1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={kabai1} alt="Kabai1" className="ImagenKabai" />
              <i className="bi bi-geo-alt-fill cafeteria"></i>
              <i className="bi bi-geo-alt-fill papeleria"></i>
              <i className="bi bi-geo-alt-fill salones"></i>
              <i className="bi bi-geo-alt-fill mercadeo"></i>
              <i className="bi bi-geo-alt-fill vrp2"></i>
              <i className="bi bi-geo-alt-fill foto"></i>
              <i className="bi bi-geo-alt-fill bañoskabai1"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={Kabair1}>Piso 1</div>
              <div onClick={Kabair2}>Piso 2</div>
              <div onClick={Kabair3}>Piso 3</div>
              <div onClick={abrirInfoKABAI}>Conoce más</div>

            </div>

          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade" id="Kabai2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={kabai2} alt="Kabai1" className="imgKabai" />
              <i className="bi bi-geo-alt-fill salonesk2"></i>
              <i className="bi bi-geo-alt-fill lab_neuro"></i>
              <i className="bi bi-geo-alt-fill lab_psi"></i>
              <i className="bi bi-geo-alt-fill programa_DA"></i>
              <i className="bi bi-geo-alt-fill baños"></i>
              <i className="bi bi-geo-alt-fill DCE"></i>
              <i className="bi bi-geo-alt-fill inter"></i>
              <i className="bi bi-geo-alt-fill acuario"></i>
              <ol className="lista_kabai">
                <li>Decanatura de Arquitectura y Diseño</li>
                <li>Dirección de Diseño Industrial</li>
                <li>Dirección de Arquitectura</li>
                <li>Dirección de Negocios Internacionales</li>
                <li>Dirección de Administración de Empresas</li>
              </ol>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={Kabair1}>Piso 1</div>
              <div onClick={Kabair2}>Piso 2</div>
              <div onClick={Kabair3}>Piso 3</div>
              <div onClick={abrirInfoKABAI}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Kabai3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={kabai3} alt="Kabai1" className="IMGkabai3" />
              <i className="bi bi-geo-alt-fill cie"></i>
              <i className="bi bi-geo-alt-fill cubiculos"></i>
              <i className="bi bi-geo-alt-fill laboratorio"></i>
              <i className="bi bi-geo-alt-fill ciesalones"></i>
              <i className="bi bi-geo-alt-fill emprendimiento"></i>
              <i className="bi bi-geo-alt-fill plataforma"></i>
              <i className="bi bi-geo-alt-fill salonesk3"></i>
              <i className="bi bi-geo-alt-fill bigdata"></i>
              <i className="bi bi-geo-alt-fill aulav1"></i>
              <i className="bi bi-geo-alt-fill salvip"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={Kabair1}>Piso 1</div>
              <div onClick={Kabair2}>Piso 2</div>
              <div onClick={Kabair3}>Piso 3</div>
              <div onClick={abrirInfoKABAI}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Buena1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={buena1} alt="Kabai1" className="buenanueva1" />
              <i className="bi bi-geo-alt-fill prodilab" ></i>
              <i className="bi bi-geo-alt-fill arquitectura"></i>
              <i className="bi bi-geo-alt-fill medio" ></i>
              <i className="bi bi-geo-alt-fill oval1" ></i>
              <i className="bi bi-arrow-up-circle-fill piso2" data-toggle="modal" data-target="#Medios2" style={{ color: "##c52c2c" }}></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosBuenaNueva">
              <div onClick={Buenair1}>Piso 1</div>
              <div onClick={Buenair2}>Piso 1 <span style={{ fontSize: "12px", marginLeft: "5px" }}> CAPSI</span> </div>
              <div onClick={Buenair3}>Piso 2</div>
              <div onClick={abrirInfoBN}>Conoce más</div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Buena2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <img loading="lazy" src={buena2} alt="Kabai1" />
                <i className="bi bi-geo-alt-fill salonesbuena2" ></i>
                <i className="bi bi-geo-alt-fill gym"></i>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosBuenaNueva">
              <div onClick={Buenair1}>Piso 1</div>
              <div onClick={Buenair2}>Piso 1 <span style={{ fontSize: "12px", marginLeft: "5px" }}> CAPSI</span> </div>
              <div onClick={Buenair3}>Piso 2</div>
              <div onClick={abrirInfoBN}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Dabar1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={dabar1} alt="Kabai1" className="Daba1Img" />
              <i className="bi bi-geo-alt-fill auditorio" ></i>
              <i className="bi bi-geo-alt-fill vrp"></i>
              <i className="bi bi-geo-alt-fill consejos"></i>
              <i className="bi bi-geo-alt-fill pb"></i>
              <i className="bi bi-geo-alt-fill salonesd1"></i>


            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={DabarIr1}>Piso 1</div>
              <div onClick={DabarIr2}>Piso 2</div>
              <div onClick={DabarIr3}>Piso 3</div>
              <div onClick={abrirInfoDABAR}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Dabar2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={dabar2} alt="Kabai1" className="imgDabar2" />
              <i className="bi bi-geo-alt-fill oval2" ></i>
              <i className="bi bi-geo-alt-fill centrocultura"></i>
              <i className="bi bi-geo-alt-fill salonesd2"></i>
              <i className="bi bi-geo-alt-fill teatrino"></i>
              <i className="bi bi-geo-alt-fill cubiculosdabar"></i>
              <i className="bi bi-geo-alt-fill gradas"></i>
              <i className="bi bi-geo-alt-fill salamultiple"></i>
              <i className="bi bi-geo-alt-fill lab_fisica"></i>
              <i className="bi bi-geo-alt-fill labii"></i>
              <i className="bi bi-geo-alt-fill ingsis"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={DabarIr1}>Piso 1</div>
              <div onClick={DabarIr2}>Piso 2</div>
              <div onClick={DabarIr3}>Piso 3</div>
              <div onClick={abrirInfoDABAR}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Dabar3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={dabar3} alt="Kabai1" className="Dabar3" />
              <i className="bi bi-geo-alt-fill salonesd3" ></i>
              <i className="bi bi-geo-alt-fill salamac "></i>
              <i className="bi bi-geo-alt-fill oval3"></i>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={DabarIr1}>Piso 1</div>
              <div onClick={DabarIr2}>Piso 2</div>
              <div onClick={DabarIr3}>Piso 3</div>
              <div onClick={abrirInfoDABAR}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Ale1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" className="kabai1Imagen" src={aletheian1} alt="Kabai1" />
              <i className="bi bi-geo-alt-fill cafeteriaroja"></i>
              <i className="bi bi-geo-alt-fill banco"></i>
              <i className="bi bi-geo-alt-fill gmercadeo"></i>
              <i className="bi bi-geo-alt-fill tiendau"></i>
              <i className="bi bi-geo-alt-fill bañosale"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={AleIr1}>Piso 1</div>
              <div onClick={AleIr2}>Piso 2</div>
              <div onClick={AleIr3}>Piso 3</div>
              <div onClick={abrirInfoALE}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Ale2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={aletheian2} alt="Kabai1" className="Ale2" />
              <i className="bi bi-geo-alt-fill financiera"></i>
              <i className="bi bi-geo-alt-fill tesoreria"></i>
              <i className="bi bi-geo-alt-fill rectoria"></i>
              <i className="bi bi-geo-alt-fill vice"></i>
              <i className="bi bi-geo-alt-fill salaprofe"></i>
              <i className="bi bi-geo-alt-fill admisiones"></i>
              <i className="bi bi-geo-alt-fill pastoral"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={AleIr1}>Piso 1</div>
              <div onClick={AleIr2}>Piso 2</div>
              <div onClick={AleIr3}>Piso 3</div>
              <div onClick={abrirInfoALE}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Ale3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>

              <img loading="lazy" src={aletheian3} alt="Kabai1" className="ale3img" />
              <i className="bi bi-geo-alt-fill gh"></i>
              <i className="bi bi-geo-alt-fill sc1"></i>
              <i className="bi bi-geo-alt-fill financiera2"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos">
              <div onClick={AleIr1}>Piso 1</div>
              <div onClick={AleIr2}>Piso 2</div>
              <div onClick={AleIr3}>Piso 3</div>
              <div onClick={abrirInfoALE}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Biblioteca1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={biblioteca1} alt="Kabai1" className="bibliotecapiso1" />
              <i className="bi bi-geo-alt-fill bibliocafe"></i>
              <i className="bi bi-geo-alt-fill infantil"></i>
              <i className="bi bi-geo-alt-fill dinv"></i>
              <i className="bi bi-geo-alt-fill referencia"></i>

            </div>
            <div className="modal-footer bibliotecafooter">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosBiblioteca">
              <div className="bibliotecapisoa" onClick={BilbliotecaIr1}>Piso 1</div>
              <div className="bibliotecapiso2a" onClick={BilbliotecaIr2}>Piso 2</div>
              <div className="bibliotecapiso3a" onClick={BilbliotecaIr3}>Piso 3</div>
              <div className="bibliotecapiso4a" onClick={BilbliotecaIr4}>Piso 4</div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Biblioteca2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={biblioteca2} alt="Kabai1" className="bibliotecapiso2" />
              <i className="bi bi-geo-alt-fill prestamos"></i>
              <i className="bi bi-geo-alt-fill emeroteca"></i>
            </div>
            <div className="modal-footer bibliotecafooter">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosBiblioteca">
              <div className="bibliotecapisoa" onClick={BilbliotecaIr1}>Piso 1</div>
              <div className="bibliotecapiso2a" onClick={BilbliotecaIr2}>Piso 2</div>
              <div className="bibliotecapiso3a" onClick={BilbliotecaIr3}>Piso 3</div>
              <div className="bibliotecapiso4a" onClick={BilbliotecaIr4}>Piso 4</div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade" id="Biblioteca3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={biblioteca3} alt="Kabai1" className="bibliotecapiso3" />
              <i className="bi bi-geo-alt-fill salag"></i>
              <i className="bi bi-geo-alt-fill salap"></i>
              <i className="bi bi-geo-alt-fill salagrupal"></i>
              <i className="bi bi-geo-alt-fill cubiculosb"></i>
            </div>
            <div className="modal-footer bibliotecafooter">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosBiblioteca">
              <div className="bibliotecapisoa" onClick={BilbliotecaIr1}>Piso 1</div>
              <div className="bibliotecapiso2a" onClick={BilbliotecaIr2}>Piso 2</div>
              <div className="bibliotecapiso3a" onClick={BilbliotecaIr3}>Piso 3</div>
              <div className="bibliotecapiso4a" onClick={BilbliotecaIr4}>Piso 4</div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade" id="Biblioteca4" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={biblioteca4} alt="Kabai1" className="bibliotecapiso4" />
              <i className="bi bi-geo-alt-fill pinacoteca"></i>
            </div>
            <div className="modal-footer bibliotecafooter">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosBiblioteca">
              <div className="bibliotecapisoa" onClick={BilbliotecaIr1}>Piso 1</div>
              <div className="bibliotecapiso2a" onClick={BilbliotecaIr2}>Piso 2</div>
              <div className="bibliotecapiso3a" onClick={BilbliotecaIr3}>Piso 3</div>
              <div className="bibliotecapiso4a" onClick={BilbliotecaIr4}>Piso 4</div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade" id="Humanitas1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" className="Humanitas1Imagen" src={humanitas1} alt="Kabai1" />
              <i className="bi bi-geo-alt-fill capilla"></i>
              <i className="bi bi-geo-alt-fill gestionh"></i>
              <i className="bi bi-geo-alt-fill practicas"></i>
              <i className="bi bi-geo-alt-fill hsalones"></i>
              <i className="bi bi-geo-alt-fill patio"></i>
              <i className="bi bi-geo-alt-fill estudiantes"></i>
              <i className="bi bi-geo-alt-fill salones2"></i>
              <i className="bi bi-geo-alt-fill dyp"></i>
              <i className="bi bi-geo-alt-fill almacen"></i>
              <i className="bi bi-geo-alt-fill bañoshumanitas"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosHumanitas">
              <div onClick={HumanitasIr1}>Piso 1</div>
              <div onClick={HumanitasIr2}>Piso 2</div>
              <div onClick={abrirInfoHUMANITAS}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade" id="Humanitas2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">

              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>

              <img className="imagenKabai2" loading="lazy" src={humanitas2} alt="Kabai1" />

              <i className="bi bi-geo-alt-fill estudiante"></i>
              <i className="bi bi-geo-alt-fill ciuc"></i>
              <i className="bi bi-geo-alt-fill comunicaciones"></i>
              <i className="bi bi-geo-alt-fill psicologia"></i>
              <i className="bi bi-geo-alt-fill direccionadm"></i>
              <i className="bi bi-geo-alt-fill archivo"></i>
              <i className="bi bi-geo-alt-fill hfche"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosHumanitas">
              <div onClick={HumanitasIr1}>Piso 1</div>
              <div onClick={HumanitasIr2}>Piso 2</div>
              <div onClick={abrirInfoHUMANITAS}>Conoce más</div>

            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade" id="Posgrados1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={posgrados1} alt="Kabai1" className="imgPosgrados1" />
              <i className="bi bi-geo-alt-fill piso1posgrados"></i>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosPosgrados">
              <div onClick={posgradosIr1}>Piso 1</div>
              <div onClick={posgradosIr2}>Piso 2</div>
              <div onClick={posgradosIr3}>Piso 3</div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Posgrados2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={posgrados2} className="posgrados2" alt="Kabai1" />
              <i className="bi bi-geo-alt-fill secrehuma"></i>
              <i className="bi bi-geo-alt-fill direccionhuma"></i>
              <i className="bi bi-geo-alt-fill posgradopegaogia"></i>
              <i className="bi bi-geo-alt-fill profesionalpeda"></i>
              <i className="bi bi-geo-alt-fill direccionlic"></i>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosPosgrados">
              <div onClick={posgradosIr1}>Piso 1</div>
              <div onClick={posgradosIr2}>Piso 2</div>
              <div onClick={posgradosIr3}>Piso 3</div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Posgrados3" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={posgrados3} alt="Kabai1" className="posgrados3img" />
              <i className="bi bi-geo-alt-fill coorpc"></i>
              <i className="bi bi-geo-alt-fill coorcgh"></i>
              <i className="bi bi-geo-alt-fill coorgpi"></i>
              <i className="bi bi-geo-alt-fill cooredumatica"></i>
              <i className="bi bi-geo-alt-fill coorpsisocial"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosPosgrados">
              <div onClick={posgradosIr1}>Piso 1</div>
              <div onClick={posgradosIr2}>Piso 2</div>
              <div onClick={posgradosIr3}>Piso 3</div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade" id="Capsi" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante " src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" className="capsiImagen" src={capsi} style={{ width: "800px", position: "relative" }} alt="Kabai1" />
              <i className="bi bi-geo-alt-fill enfermeria"></i>
              <i className="bi bi-geo-alt-fill capsi"></i>
              <i className="bi bi-geo-alt-fill cubicapsi"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            <div className="iconosPisos pisosBuenaNueva">
              <div onClick={Buenair1}>Piso 1</div>
              <div onClick={Buenair2}>Piso 1 <span style={{ fontSize: "12px", marginLeft: "5px" }}> CAPSI</span> </div>
              <div onClick={Buenair3}>Piso 2</div>
              <div onClick={abrirInfoBN}>Conoce más</div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade modalrojo" id="Canchas" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">

              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px", width: "500px" }}>
                <Carousel interval={null} fade>
                  <Carousel.Item>
                    <img style={{ filter: "brightness(1.7)" }} loading="lazy" className="ImagenCambiante" src={cancha} alt="Kabai1" />

                  </Carousel.Item>
                  <Carousel.Item>
                    <img loading="lazy" className="ImagenCambiante" src={cancha2} alt="Kabai1" />

                  </Carousel.Item>
                  <Carousel.Item>
                    <img loading="lazy" className="ImagenCambiante" src={cancha3} alt="Kabai1" />

                  </Carousel.Item>
                </Carousel>
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Canchas</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="Plazoleta" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">

              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={plazoleta} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Plazoleta 14 de Febrero</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="Entradaprincipal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">

              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={entradap} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Entrada principal</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="Entradanormandia" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">
              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={entradan} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Entrada 2- Peatonal</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade" id="Medios2" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">
              <div className="wrapperCambiante">
                <img loading="lazy" className="ImagenCambiante" src={imagen} alt="Kabai1" style={{ width: "350px", height: "250px", left: "-200px", bottom: 0 }} />
                <div className="NombreCambiante">
                  <h3>{texto}</h3>
                </div>
              </div>
              <img loading="lazy" src={medios2} alt="Kabai1" className="Medios2" />
              <i className="bi bi-geo-alt-fill radio" />
              <i className="bi bi-geo-alt-fill salonmedio2"></i>

            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="parqueadero" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">
              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={parqueadero_pri} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Parqueadero</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade modalrojo" id="parqueaderomotos" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">
              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={parqueadero_motos} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Parqueadero Motos</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>




      <div className="modal modal_mapa fade modalrojo" id="rioconsota" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">
              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={rioconsota} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Río Consotá</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="parqueaderoadm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">
              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={parqueadero_adm} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Parqueadero de docentes y administrativos</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="parquebanderas" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body modal_unico">
              <div className="wrapperCambiante" style={{ marginRight: "0", marginBottom: "30px" }}>
                <img loading="lazy" className="ImagenCambiante" src={banderas} alt="Kabai1" />
                <div className="NombreCambiante NombreCambianteSolito">
                  <h3>Plazoleta de Banderas</h3>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>


      {/*       INFORMACIÓN DE LOS BLOQUES            */}

      <div className="modal modal_mapa fade modalrojo" id="infobuenanueva" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <ContenedorModal>
              <p style={{ color: "white" }}>  En los primeros textos cristianos, se dice eu - angelino, EVANGELIO. La noticia es buena porque lo que anuncia contribuye a la felicidad de las personas, y proclama un acontecimiento que satisface las necesidades e ilusiones de la comunidad siendo veraz y digna de crédito. Toda noticia puede volverse "buena nueva" en la medida en que sea contada para construir y hacer el bien. </p>
            </ContenedorModal>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade modalrojo" id="infodabar" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <ContenedorModal>
              <p style={{ color: "white" }}> Dabar es “palabra, cosa, asunto, mandamiento, reporte, mensaje, camino, ley, testimonio, dichos, juicios" (Gen 1,3s). Dios crea hablando, pronunciando su palabra. Todo lo que el Padre ha hecho, lo ha hecho por medio de su dabar, sin Dabar, "nada de lo que ha sido hecho, fue hecho “(Jn 1,14). El Dabar hecho carne es Jesús". </p>
            </ContenedorModal>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal modal_mapa fade modalrojo" id="infokabai" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <ContenedorModal>
              <p style={{ color: "white" }}>Es una voz de la lengua Katia, hablada por nuestra población indígena de la familia emberá chami. KABAI significa, en una primera acepción, aprender, saber, conocer, estudiar (en otra forma: kababoai o kauaboai) y, en una segunda acepción, trabajar, laborar, cultivar.  </p>
            </ContenedorModal>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="infoale" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <ContenedorModal>
              <p style={{ color: "white" }}>La palabra aletheia (ancia) significa en sus orígenes, la no ocultación, el desvelamiento de la realidad, lo que da la idea de que la realidad está como oculta a nuestros ojos y a nuestra razón. </p>
            </ContenedorModal>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal_mapa fade modalrojo" id="infohumanitas" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <ContenedorModal>
              <p style={{ color: "white" }}>Es una palabra latina que significa humanidad, humanidades, humanismo. La educación es el proceso mediante el cual nos apropiamos de la humanitas, nos formamos como humanos y llegamos a ser PERSONAS dignas, singulares y autónomas. La HUMANITAS es todo lo digno, hermoso, noble, excelente, bello, trascendente, armónico. La humanitas nos da la condición de seres dignos, refleja nuestra trascendencia y nos caracteriza como "humanos”. </p>
            </ContenedorModal>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>
      </div>

    </div >


  )
}

export default MapaModal;


const abrirInfoBN = () => {
  $("#infobuenanueva").modal("show");
}

const abrirInfoDABAR = () => {
  $("#infodabar").modal("show");
}

const abrirInfoKABAI = () => {
  $("#infokabai").modal("show");
}

const abrirInfoALE = () => {
  $("#infoale").modal("show");
}

const abrirInfoHUMANITAS = () => {
  $("#infohumanitas").modal("show");
}

$(document).on('show.bs.modal', function () {
  $("body").css("overflow", "hidden")
})


$(document).on('hide.bs.modal', function () {
  $("body").css("overflow", "auto")
})

const ContenedorModal = styled.div`
            width: 700px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: justify;
            background-color: rgba(255, 255, 255, 0.15);
            padding: 20px;
            border-radius: 10px;
            text-shadow: 1px 1px 5px black;
            @media (max-width: 801px) {
                height: auto;
                width: 90vw;

  }
            `