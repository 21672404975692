import React, { useEffect } from "react";
import "./App.css";
import "jquery-ui-dist/jquery-ui";
import Universidad from "./U-Virtual/Componentes/Universidad";
import Search from "./U-Virtual/Componentes/Serach";
import Informativas from "./U-Virtual/Componentes/Informativas";
import Servicios from "./U-Virtual/Componentes/Servicios";
import Footer from "./U-Virtual/views/Footer";
import Header from "./U-Virtual/views/Header";
import ErrorPage from "./U-Virtual/views/Error";
import Formulario from "./U-Virtual/views/Formulario";
import Slidernav from "./U-Virtual/views/Slidernav";
import $ from "jquery"
import { Route, Routes } from "react-router-dom";

function App() {


  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Universidad />} />

        <Route path='/*' element={<ErrorPage />} />

        <Route
          path='/search/:producto'
          element={
            <>
              <Search />
            </>
          }
        />
        <Route
          path='/info/diplomado_EMD'
          element={
            <div >
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/636eb2c4fb3f910011c2c235'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </div>
          }
        />

        <Route
          path='/info/rectoria'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Rectoría'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646e16d9f63c2b001942347e'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/vicerrectoria'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646e2c1cb2a07e0018cbcc87'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/vicerrectoria_proyecto_de_vida'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646e7a6bb2a07e0018cc907c'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/admisiones_registro'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646d3aa866ee9e001a52ee80'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/gestion_financiera'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646e68a798f67f001122c6b5'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/centro_idiomas'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/6261c6bb7ab7b70013e92a91'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/internacionalizacion'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646e1f9bb2a07e0018cba2f0'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/biblioteca'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646e350e3fce0a001950b936'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/practicas_graduados'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/647134740c3d4a0012e540a0'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/cie'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/646d1f616f20b90011adf3a3'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/paso_digital'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/637b858cb7ab7c00116c2d0f'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />



        <Route
          path='/info/gestion_citacion'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/636ec0d03babc20010425860'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path='/info/maestria_innovacion'
          element={
            <>
              <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/6373b938352fa90012817eb1'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />

        <Route
          path='/info/aprendizaje_en_linea'
          element={
            <>
             <div style={{ height: "80px", backgroundColor: "#77383E" }}>
                <Header />
              </div>
              <Slidernav />
              <div>
                <iframe
                  title='Proyecto de Vida'
                  frameBorder='0'
                  width='1920px'
                  height='1080px'
                  style={{
                    position: "relative",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "70vh",
                  }}
                  src='https://view.genial.ly/6373c057352fa9001281977f'
                  type='text/html'
                  allowscriptaccess='always'
                  allowFullScreen
                  scrolling='yes'
                  allownetworking='all'
                ></iframe>
              </div>
              <Footer />
            </>
          }
        />
        <Route
          path='/formulario'
          element={
            <>

              <Formulario />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
