import React, { useEffect } from "react";
import $ from "jquery"
import Inicio from "../views/Inicio";
import Header from "../views/Header";
import Slidernav from "../views/Slidernav";
import Mapa from "../views/Mapa";
import Personas from "../views/Personas";
import Ofertas from "../views/Ofertas";
import Facultad from "../views/Facultad";
import Contactos from "../views/Contactos";
import Footer from "../views/Footer";
import IconsFijos from "../views/IconsFijos";
import Modal from "../../Modal/Modal";
import MapaModal from "../../Modal/MapaModal";
import Ayuda from "../views/Ayuda";
import PersonasSlider from "../views/PersonasSlider";
import ModalReu from "../../Modal/ModalReu";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Lupa = require(`../img/lupa.png`);



function Universidad() {

    useEffect(() => {
        $(".radiotodos").prop('checked', true);

    }, [])



    const msg = `FORMACIÓN \n CONTINUA`

    /* SE CONSUMEN LAS CARPETAS DE IMAGENES */

    const imagenPersonas = require.context("../img/Personas", true);
    const imagenFacultad = require.context("../img/Facultad", true);
    const imagenProgramas = require.context("../img/Programas", true);


    /* SE USA PARA LA VISIBILIDAD DE LOS MODALES */

    const [estadoModal1, cambiarEstadoMordal1] = useState(false);
    const [estadoModal2, cambiarEstadoMordal2] = useState(false);
    const [estadoModal3, cambiarEstadoMordal3] = useState(false);
    const [estadoModal4, cambiarEstadoMordal4] = useState(false);
    const [estadoModal5, cambiarEstadoMordal5] = useState(false);
    const [estadoModal6, cambiarEstadoMordal6] = useState(false);
    const [estadoModal7, cambiarEstadoMordal7] = useState(false);
    const [estadoModal8, cambiarEstadoMordal8] = useState(false);
    const [estadoModal9, cambiarEstadoMordal9] = useState(false);
    const [estadoModal10, cambiarEstadoMordal10] = useState(false);
    const [estadoModal11, cambiarEstadoMordal11] = useState(false);
    const [estadoModal13, cambiarEstadoMordal13] = useState(false);
    const [estadoModal14, cambiarEstadoMordal14] = useState(false);
    const [estadoModal16, cambiarEstadoMordal16] = useState(false);
    const [estadoModal17, cambiarEstadoMordal17] = useState(false);


    function valor() {
        var singleValues = $(".CampoLupa").val();

        if (singleValues == "") {
            alert("Ingresa un valor")
        } else {
            window.location.href = `/search/${singleValues}`;

        }
    }


    function Menu() {
        $(".dobyHumanitas").hide();
        $(".dobyKabai").hide();
        $(".dobyDabar").hide();
        $(".dobyBiblioteca").hide();
        $(".dobyBuenaNueva").hide();
        $(".dobyPosgrados").hide();
        $(".dobyAletheia").hide();

        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")


        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")
    }

    function MostrarLupa() {

        const el = document.querySelector(".input_visible");

        if (el == null) {
            $(".InputBuscar").css("visibility", "visible")
            $(".InputBuscar").addClass("input_visible")
        } else {
            $(".InputBuscar").css("visibility", "hidden")
            $(".InputBuscar").removeClass("input_visible")
        }

        console.log(el)


    }


    $(document).ready(function () {
        $("#CampoLupa2").keypress(function (e) {
            if (e.which == 13) {
                var singleValues = $("#CampoLupa2").val();
                window.location.href = `/search/${singleValues}`;
            }
        });
    });


    return (
        <>

            <div >
                <Modal />
                <MapaModal />
                <ModalReu
                    estado={estadoModal1}
                    cambiarEstado={cambiarEstadoMordal1}
                >
                    <iframe
                        title='Rectoría Virtual'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646e16d9f63c2b001942347e'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>

                </ModalReu>


                <ModalReu
                    estado={estadoModal2}
                    cambiarEstado={cambiarEstadoMordal2}
                >
                    <iframe
                        title='Vicerrectoría Academica'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646e2c1cb2a07e0018cbcc87'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal3}
                    cambiarEstado={cambiarEstadoMordal3}
                >
                    <iframe
                        title='Vicerrectoría de Proyecto de Vida'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646e7a6bb2a07e0018cc907c'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal4}
                    cambiarEstado={cambiarEstadoMordal4}
                >
                    <iframe
                        title='Admisiones y Registro'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646d3aa866ee9e001a52ee80'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal5}
                    cambiarEstado={cambiarEstadoMordal5}
                >
                    <iframe
                        title='Gestión Financiera'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646e68a798f67f001122c6b5'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal6}
                    cambiarEstado={cambiarEstadoMordal6}
                >
                    <iframe
                        title='Centro de Idiomas'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/6261c6bb7ab7b70013e92a91'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal7}
                    cambiarEstado={cambiarEstadoMordal7}
                >
                    <iframe
                        title='Internacionalización'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646e1f9bb2a07e0018cba2f0'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal8}
                    cambiarEstado={cambiarEstadoMordal8}
                >
                    <iframe
                        title='Biblioteca'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646e350e3fce0a001950b936'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal9}
                    cambiarEstado={cambiarEstadoMordal9}
                >
                    <iframe
                        title='Prácticas y Graduados'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/647134740c3d4a0012e540a0'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal10}
                    cambiarEstado={cambiarEstadoMordal10}
                >
                    <iframe
                        title='Centro de Innovación Educativa'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/646d1f616f20b90011adf3a3'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal11}
                    cambiarEstado={cambiarEstadoMordal11}
                >
                    <iframe
                        title='Mi primer paso digital'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/637b858cb7ab7c00116c2d0f'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>



                <ModalReu
                    estado={estadoModal13}
                    cambiarEstado={cambiarEstadoMordal13}
                >
                    <iframe
                        title='Diplomado en evaluación mediada por tic'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/636eb2c4fb3f910011c2c235'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal14}
                    cambiarEstado={cambiarEstadoMordal14}>
                    <iframe
                        title='Gestión de la Citación y Referenciación Académica'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/636ec0d03babc20010425860'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'>
                    </iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal16}
                    cambiarEstado={cambiarEstadoMordal16}
                >
                    <iframe
                        title='Maestria en Innovacion Educativa'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/6373b938352fa90012817eb1'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>

                <ModalReu
                    estado={estadoModal17}
                    cambiarEstado={cambiarEstadoMordal17}
                >
                    <iframe
                        title='Habilidades para el aprendizaje en linea'
                        frameBorder='0'
                        width='1920px'
                        height='1080px'
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",

                        }}
                        src='https://view.genial.ly/6373c057352fa9001281977f'
                        type='text/html'
                        allowscriptaccess='always'
                        allowFullScreen
                        scrolling='yes'
                        allownetworking='all'
                    ></iframe>
                </ModalReu>
            </div>
            <Ayuda />

            <Header />

            <IconsFijos id="iconosPosicion" >
                <img loading="lazy" src={Lupa} alt='Lupa' className="LupaBuscar" onClick={MostrarLupa} />

                <div className='input-group mb- InputBuscar'>
                    <input
                        type='text'
                        className='form-control CampoLupa'
                        placeholder='Buscar'
                        id='CampoLupa2'
                    />
                    <div className='input-group-append' >
                        <a onClick={valor} className='btn btn-outline-secondary' >
                            <i className='bi bi-search'></i>
                        </a>
                    </div>
                </div>
            </IconsFijos>

            <Slidernav />

            <div className="PrincipalUniversidad" id="PrincipalUniversidad">

                <section className='inicio' id='inicio' onClick={Menu}>
                    <Inicio />
                </section>


                <div className="containerMapa">
                    <section className='mapa' id='mapa'>
                        <Mapa />
                    </section>
                </div>

                <section className='personas_cont' id='servicios_virtuales' onClick={Menu}>
                    <AnimationOnScroll animateIn="animate__fadeIn">

                        <h1>Servicios virtuales</h1>
                        <div className='personasPrincipal'>
                            <div className='personas_container' id='Talleristas'>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./Rector.webp")}
                                                alt='Imagen'
                                                onClick={() => cambiarEstadoMordal1(!estadoModal1)}
                                                loading="lazy"
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal1(!estadoModal1)}
                                                href='#!'
                                                id='amarillo rector'
                                                className="amarillo"
                                                style={{ backgroundColor: "#b28532" }}
                                            >
                                                Rectoría Virtual
                                            </a>
                                        </div>
                                    </div>
                                </Personas>

                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./Vice.webp")}
                                                onClick={() => cambiarEstadoMordal2(!estadoModal2)}
                                                alt='Imagen'
                                                loading="lazy"
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal2(!estadoModal2)}
                                                href='#!'
                                                id='rojo'
                                                className="rojo"
                                                style={{ backgroundColor: "#76383d" }}
                                            >
                                                Vicerrectoría Académica
                                            </a>
                                        </div>
                                    </div>
                                </Personas>

                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./estudiantes.webp")}
                                                onClick={() => cambiarEstadoMordal3(!estadoModal3)}
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal3(!estadoModal3)}
                                                href='#!'
                                                id='morado'
                                                className="morado"
                                                style={{ backgroundColor: "#514172" }}
                                            >
                                                Vicerrectoría de Proyecto de Vida
                                            </a>
                                        </div>
                                    </div>
                                </Personas>

                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./Admisiones.png")}
                                                onClick={() => cambiarEstadoMordal4(!estadoModal4)}
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal4(!estadoModal4)}
                                                href='#!'
                                                id='azul'
                                                className="azul"
                                                style={{ backgroundColor: "#10344c" }}
                                            >
                                                Admisiones y Registro
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./Financiera.webp")}
                                                onClick={() => cambiarEstadoMordal5(!estadoModal5)}
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal5(!estadoModal5)}
                                                href='#!'
                                                id='aqua'
                                                className="aqua"
                                                style={{ backgroundColor: "#429b85" }}
                                            >
                                                Gestión Financiera
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./Idiomas.webp")}
                                                onClick={() => cambiarEstadoMordal6(!estadoModal6)}
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal6(!estadoModal6)}
                                                href='#!'
                                                id='verde'
                                                className="verde"
                                                style={{ backgroundColor: "#6d803a" }}
                                            >
                                                Centro de Idiomas
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./Inter.webp")}
                                                onClick={() => cambiarEstadoMordal7(!estadoModal7)}
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal7(!estadoModal7)}
                                                href='#!'
                                                id='amarillo'
                                                className="amarillo"
                                                style={{ backgroundColor: "#b28532" }}
                                            >
                                                Internacionalización
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./biblioteca.webp")}
                                                onClick={() => cambiarEstadoMordal8(!estadoModal8)}
                                                alt='Imagen'
                                                loading='lazy'
                                                className=""
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal8(!estadoModal8)}
                                                href='#!'
                                                id='rojo'
                                                className="rojo"
                                                style={{ backgroundColor: "#76383d" }}
                                            >
                                                Biblioteca
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./practica_graduados.webp")}
                                                onClick={() => cambiarEstadoMordal9(!estadoModal9)}
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() => cambiarEstadoMordal9(!estadoModal9)}
                                                href='#!'
                                                id='morado'
                                                className="morado"
                                                style={{ backgroundColor: "#514172" }}
                                            >
                                                Prácticas y Graduados
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./fotocie.jpg")}
                                                onClick={() =>
                                                    cambiarEstadoMordal10(!estadoModal10)
                                                }
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() =>
                                                    cambiarEstadoMordal10(!estadoModal10)
                                                }
                                                href='#!'
                                                id='azul'
                                                className="azul"
                                                style={{ backgroundColor: "#10344c" }}
                                            >
                                                Centro de Innovación Educativa
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./paso_digital.webp")}
                                                onClick={() =>
                                                    cambiarEstadoMordal11(!estadoModal11)
                                                }
                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={() =>
                                                    cambiarEstadoMordal11(!estadoModal11)
                                                }
                                                href='#!'
                                                id='aqua'
                                                className="aqua"
                                                style={{ backgroundColor: "#429b85" }}
                                            >
                                                Mi primer paso digital
                                            </a>
                                        </div>
                                    </div>
                                </Personas>

                                <Personas>
                                    <div className='cardCompleta'>
                                        <div className='imagen'>
                                            <img
                                                src={imagenPersonas("./campus.webp")}

                                                onClick={irNoticias}

                                                alt='Imagen'
                                                loading='lazy'
                                            ></img>
                                        </div>
                                        <div className='nombre'>
                                            <a
                                                onClick={irNoticias}
                                                href='#!'
                                                id='verde'
                                                className="verde"
                                                style={{ backgroundColor: "#6d803a" }}
                                            >
                                                Campus al día
                                            </a>
                                        </div>
                                    </div>
                                </Personas>
                            </div>
                        </div>
                    </AnimationOnScroll>

                </section>

                <section className='personas_cont2' id='servicio_virtual' onClick={Menu}>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <div className='personas_container2' id='Talleristas'>
                            <div
                                uk-slider='{getValue.toString()}'
                                className='uk-position-relative'
                            >
                                <div className='uk-slider-container itemsSliderTalleristas2  uk-light'>
                                    <h1>Servicios virtuales</h1>

                                    <ul
                                        className='uk-slider-items uk-child-width-1 uk-child-width-1-1@s uk-child-width-1-1@m'
                                        id='items_slider'
                                    >
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./Rector.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal1(!estadoModal1)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal1(!estadoModal1)
                                                        }
                                                        href='#!'
                                                        id='amarillo rector'
                                                        style={{ backgroundColor: "#b28532" }}
                                                    >
                                                        Rectoría Virtual
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>

                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./Vice.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal2(!estadoModal2)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal2(!estadoModal2)
                                                        }
                                                        href='#!'
                                                        id='rojo'
                                                        style={{ backgroundColor: "#76383d" }}
                                                    >
                                                        Vicerrectoría Académica
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>

                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./estudiantes.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal3(!estadoModal3)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal3(!estadoModal3)
                                                        }
                                                        href='#!'
                                                        id='morado'
                                                        style={{ backgroundColor: "#514172" }}
                                                    >
                                                        Vicerrectoría de Proyecto de Vida
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>

                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./Admisiones.png")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal4(!estadoModal4)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal4(!estadoModal4)
                                                        }
                                                        href='#!'
                                                        id='azul'
                                                        style={{ backgroundColor: "#10344c" }}
                                                    >
                                                        Admisiones y Registro
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./Financiera.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal5(!estadoModal5)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal5(!estadoModal5)
                                                        }
                                                        href='#!'
                                                        id='aqua'
                                                        style={{ backgroundColor: "#429b85" }}
                                                    >
                                                        Gestión Financiera
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./Idiomas.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal6(!estadoModal6)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal6(!estadoModal6)
                                                        }
                                                        href='#!'
                                                        id='aqua'
                                                        style={{ backgroundColor: "#6d803a" }}
                                                    >
                                                        Centro de Idiomas
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas(
                                                            "./Inter.webp"
                                                        )}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal7(!estadoModal7)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal7(!estadoModal7)
                                                        }
                                                        href='#!'
                                                        id='amarillo'
                                                        style={{ backgroundColor: "#b28532" }}
                                                    >
                                                        Internacionalización
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen '>
                                                    <img
                                                        src={imagenPersonas("./biblioteca.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal8(!estadoModal8)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal8(!estadoModal8)
                                                        }
                                                        href='#!'
                                                        id='rojo'
                                                        style={{ backgroundColor: "#76383d" }}
                                                    >
                                                        Biblioteca
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas(
                                                            "./practica_graduados.webp"
                                                        )}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal9(!estadoModal9)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal9(!estadoModal9)
                                                        }
                                                        href='#!'
                                                        id='morado'
                                                        style={{ backgroundColor: "#514172" }}
                                                    >
                                                        Prácticas y Graduados
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./fotocie.jpg")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal10(!estadoModal10)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal10(!estadoModal10)
                                                        }
                                                        href='#!'
                                                        id='azul'
                                                        style={{ backgroundColor: "#10344c" }}
                                                    >
                                                        Centro de Innovación Educativa
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./paso_digital.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal11(!estadoModal11)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={() =>
                                                            cambiarEstadoMordal11(!estadoModal11)
                                                        }
                                                        href='#!'
                                                        id='aqua'
                                                        style={{ backgroundColor: "#429b85" }}
                                                    >
                                                        Mi primer paso digital
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>

                                        <PersonasSlider>
                                            <div className='cardCompleta'>
                                                <div className='imagen'>
                                                    <img
                                                        src={imagenPersonas("./campus.webp")}
                                                        alt='Imagen'
                                                        loading='lazy'
                                                        onClick={() =>
                                                            cambiarEstadoMordal12(!estadoModal12)
                                                        }
                                                    ></img>
                                                </div>
                                                <div className='nombre'>
                                                    <a
                                                        onClick={irNoticias}
                                                        href='#!'
                                                        id='aqua'
                                                        style={{ backgroundColor: "#6d803a" }}
                                                    >
                                                        Campus al día
                                                    </a>
                                                </div>
                                            </div>
                                        </PersonasSlider>
                                    </ul>
                                </div>
                                <ul
                                    className='padding_boton uk-slider-nav uk-dotnav uk-flex-center botones_color uk-position-relative'
                                    style={{ color: "white" }}
                                ></ul>
                            </div>
                        </div>
                    </AnimationOnScroll>
                </section>

                <section className='container_ofertaVirtual' id='oferta_virtual' onClick={Menu}>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <div className="container_OfertaVirtual">
                            <div className="containerTituloOferta">
                                <p>Oferta virtual</p>
                            </div>
                            <div className="container_card ofertasflex">
                                <div className="containerFiltro">
                                    <div className="container_filtro">
                                        <h1>Filtro</h1>
                                    </div>
                                    <div className="itemInput">
                                        <input
                                            type="radio"
                                            className="radiotodos"
                                            id="todos"
                                            name="filtro"


                                        /><label htmlFor="todos" style={{ marginLeft: "20px" }}>TODOS</label>

                                    </div>
                                    <div className="itemInput">
                                        <input
                                            type="radio"
                                            className=""
                                            id="facultad"
                                            name="filtro"

                                        /><label htmlFor="facultad" style={{ marginLeft: "20px" }}>FACULTADES</label>


                                    </div>

                                    <div className="itemInput itemInputFacultad">
                                        <input
                                            type="radio"
                                            className=""
                                            id="facultad_arquitectura"
                                            name="filtro"

                                        /><label htmlFor="facultad_arquitectura" style={{ marginLeft: "20px" }}>Arquitectura y Diseño</label>


                                    </div>

                                    <div className="itemInput itemInputFacultad">
                                        <input
                                            type="radio"
                                            className=""
                                            id="facultad_cienciasHumanas"
                                            name="filtro"

                                        /><label htmlFor="facultad_cienciasHumanas" style={{ marginLeft: "20px" }}>Ciencias Humanas, Sociales y de la Educación</label>


                                    </div>

                                    <div className="itemInput itemInputFacultad">
                                        <input
                                            type="radio"
                                            className=""
                                            id="facultad_cienciasEconomicas"
                                            name="filtro"

                                        /><label htmlFor="facultad_cienciasEconomicas" style={{ marginLeft: "20px" }}>Ciencias Económicas y Administrativas</label>


                                    </div>

                                    <div className="itemInput itemInputFacultad">
                                        <input
                                            type="radio"
                                            className=""
                                            id="facultad_cienciasBasicas"
                                            name="filtro"

                                        /><label htmlFor="facultad_cienciasBasicas" style={{ marginLeft: "20px" }}>Ciencias Básicas e Ingeniería</label>


                                    </div>
                                    <div className="itemInput">
                                        <input
                                            type="radio"
                                            className=""
                                            id="formacion"
                                            name="filtro"

                                        /><label htmlFor="formacion" style={{ marginLeft: "20px" }}>{msg}</label>

                                    </div>

                                </div>
                                <Ofertas>

                                    <div className="cardCompleta" id="continua" >
                                        <div className="imagen ofertas" id="diplomado" onClick={() =>
                                            cambiarEstadoMordal13(!estadoModal13)
                                        } >
                                            <img loading="lazy" src={imagenProgramas("./diplomado.png")} alt="" />
                                            <p>Diplomado en evaluación mediada por TIC</p>
                                        </div>
                                    </div>

                                    <div className="cardCompleta" id="continua">
                                        <div className="imagen ofertas" id="citacion" onClick={() =>
                                            cambiarEstadoMordal14(!estadoModal14)

                                        }>
                                            <img loading="lazy" src={imagenProgramas("./citacion.png")} alt="" />
                                            <p>Gestión de la citación y referenciación</p>
                                        </div>
                                    </div>

                                    <div className="cardCompleta" id="continua">
                                        <div className="imagen ofertas" id="habilidades" onClick={() =>
                                            cambiarEstadoMordal17(!estadoModal17)
                                        }>
                                            <img loading="lazy" src={imagenProgramas("./habilidades.png")} alt="" />
                                            <p>Habilidades para el aprendizaje en línea</p>
                                        </div>
                                    </div>

                                    <div className="cardCompleta CHSE" id="fac_id">
                                        <div className="imagen ofertas" id="mid" onClick={() =>
                                            cambiarEstadoMordal16(!estadoModal16)
                                        }>
                                            <img loading="lazy" src={imagenProgramas("./maestria.png")} alt="" />
                                            <p>Maestría en Innovación Educativa</p>
                                        </div>
                                    </div>

                                </Ofertas>

                            </div>
                        </div>
                    </AnimationOnScroll>
                </section>

                <section className='facultadesContainer' onClick={Menu}>
                    <AnimationOnScroll animateIn="animate__fadeIn">
                        <Facultad>
                            <div className='cardCompleta' id="fad" onClick={() => { window.open('https://www.ucp.edu.co/facultades/arquitectura-y-diseno/', '_blank'); }}>
                                <div className='imagen imgfacultad'>
                                    <img src={imagenFacultad("./arquitectura.png")} alt='' loading="lazy" />
                                    <p>Facultad de Arquitectura y Diseño</p>
                                </div>

                            </div>

                            <div className='cardCompleta' onClick={() => { window.open('https://www.ucp.edu.co/facultades/ciencias-humanas-sociales-educacion/', '_blank'); }}>
                                <div className='imagen imgfacultad'>
                                    <img
                                        src={imagenFacultad("./ciencias_humanas.png")}
                                        alt=''
                                        loading="lazy"
                                    />
                                    <p>Facultad de Ciencias Humanas, Sociales y de la Educación</p>
                                </div>

                            </div>

                            <div className='cardCompleta' id="fcbi" onClick={() => { window.open('https://www.ucp.edu.co/facultades/ciencias-economicas-y-administrativas/', '_blank'); }}>
                                <div className='imagen imgfacultad'>
                                    <img
                                        src={imagenFacultad("./ciencias_economicas.png")}
                                        alt=''
                                        loading="lazy"

                                    />
                                    <p>Facultad de Ciencias Económicas y Administrativas</p>
                                </div>
                            </div>

                            <div className='cardCompleta' onClick={() => { window.open('https://www.ucp.edu.co/facultades/ciencias-basicas-ingenieria/', '_blank'); }}>
                                <div className='imagen imgfacultad'>
                                    <img
                                        src={imagenFacultad("./ciencias_basicas.png")}
                                        alt=''
                                        loading="lazy"
                                    />
                                    <p>Facultad de Ciencias Básicas e Ingeniería</p>
                                </div>

                            </div>
                        </Facultad>
                    </AnimationOnScroll>
                </section>

                <section className='contactos_fijos' id='contactos_fijos' onClick={Menu}>
                    <Contactos />
                </section>

                <Footer />


                <div id='resno'></div>
                <div id='ressi'></div>
            </div>
        </>
    )

}



export default Universidad;

function irNoticias() {
    var y = new Date().getFullYear();

    var myWindow = window.open(`https://www.ucp.edu.co/${y}`, "_blank");

}