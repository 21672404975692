import React from "react";
import "../css/Facultad.css";

function Facultad({ children }) {

    
  return (
    <div className="container_card">
      <div className="containerTituloOferta2">
        <p>Facultades</p>
      </div>
      <div className="items_card3" id="items_card3">
        {children}
      </div>
    </div>
  );
}

export default Facultad