import React from "react";
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
import { useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import "../css/Mapa.css";
import ImagenMapa2 from "../img/mapa.png";


function Mapa() {


  function Menu() {
    $(".dobyHumanitas").hide();
    $(".dobyKabai").hide();
    $(".dobyDabar").hide();
    $(".dobyBiblioteca").hide();
    $(".dobyBuenaNueva").hide();
    $(".dobyPosgrados").hide();
    $(".dobyAletheia").toggle("", 0);


  }

  function Menu2() {
    $(".dobyAletheia").hide();
    $(".dobyKabai").hide();
    $(".dobyDabar").hide();
    $(".dobyBiblioteca").hide();
    $(".dobyBuenaNueva").hide();
    $(".dobyPosgrados").hide();
    $(".dobyHumanitas").toggle("", 0);

  }

  function Menu3() {
    $(".dobyAletheia").hide();
    $(".dobyHumanitas").hide();
    $(".dobyDabar").hide();
    $(".dobyBiblioteca").hide();
    $(".dobyBuenaNueva").hide();
    $(".dobyPosgrados").hide();
    $(".dobyKabai").toggle("", 0);

  }

  function Menu4() {
    $(".dobyAletheia").hide();
    $(".dobyHumanitas").hide();
    $(".dobyKabai").hide();
    $(".dobyBiblioteca").hide();
    $(".dobyBuenaNueva").hide();
    $(".dobyPosgrados").hide();
    $(".dobyDabar").toggle("", 0);

  }

  function Menu5() {
    $(".dobyAletheia").hide();
    $(".dobyHumanitas").hide();
    $(".dobyKabai").hide();
    $(".dobyDabar").hide();
    $(".dobyBuenaNueva").hide();
    $(".dobyPosgrados").hide();
    $(".dobyBiblioteca").toggle("", 0);

  }


  function Menu6() {
    $(".dobyAletheia").hide();
    $(".dobyHumanitas").hide();
    $(".dobyKabai").hide();
    $(".dobyDabar").hide();
    $(".dobyBiblioteca").hide();
    $(".dobyPosgrados").hide();
    $(".dobyBuenaNueva").toggle("", 0);


  }

  function Menu7() {
    $(".dobyAletheia").hide();
    $(".dobyHumanitas").hide();
    $(".dobyKabai").hide();
    $(".dobyDabar").hide();
    $(".dobyBiblioteca").hide();
    $(".dobyBuenaNueva").hide();
    $(".dobyPosgrados").toggle("", 0);

  }

  useEffect(() => {

    $("i#geo_1").hover(
      function () {
        $("#buena_nueva").css("visibility", "visible")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "visible")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {

      }
    );

    $("i#geo_2").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "visible")

        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "visible")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {

      }
    );

    $("i#geo_3").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "visible")

        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "visible")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {

      }
    );

    $("i#geo_4").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "visible")

        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "visible")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {

      }
    );

    $("i#geo_5").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "visible")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "visible")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_7").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "visible")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "visible")

      }, function () {

      }
    );

    $("i#geo_6").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "visible")

        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "visible")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {

      }
    );

    $("i#geo_8").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "visible")

        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_9").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "visible")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_10").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "visible")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_11").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "visible")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")


        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_12").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "visible")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")


        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_13").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "visible")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")


        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_14").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "visible")

        $("#parquebanderas2").css("visibility", "hidden")

        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")

      }, function () {
      }
    );

    $("i#geo_15").hover(
      function () {
        $("#buena_nueva").css("visibility", "hidden")
        $("#dabar").css("visibility", "hidden")
        $("#kabai").css("visibility", "hidden")
        $("#aletheian").css("visibility", "hidden")
        $("#biblioteca").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "visible")
        $("#rioconsota2").css("visibility", "hidden")
        $("#parquebanderas2").css("visibility", "hidden")


        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#dabarbtn").css("visibility", "hidden")
        $("#kabaibtn").css("visibility", "hidden")
        $("#aletheianbtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")
        $("#humanitasbtn").css("visibility", "hidden")
        $("#posgradosbtn").css("visibility", "hidden")
      }, function () {
      }
    );

    $("i#geo_16").hover(
      function () {
        $("#parquebanderas2").css("visibility", "visible")
        $("#biblioteca").css("visibility", "hidden")

        $("#dabar").css("visibility", "hidden")
        $("#humanitas").css("visibility", "hidden")
        $("#posgrados").css("visibility", "hidden")
        $("#cancha").css("visibility", "hidden")
        $("#plazoleta").css("visibility", "hidden")
        $("#entradanormandia").css("visibility", "hidden")
        $("#entradaprincipal").css("visibility", "hidden")
        $("#parqueadero2").css("visibility", "hidden")
        $("#parqueaderomotos2").css("visibility", "hidden")
        $("#parqueaderoadm2").css("visibility", "hidden")
        $("#rioconsota2").css("visibility", "hidden")


        $("#dabarbtn").css("visibility", "hidden")
        $("#buena_nuevabtn").css("visibility", "hidden")
        $("#bibliotecabtn").css("visibility", "hidden")

      }, function () {
        $("#parquebanderas2").css("visibility", "hidden")
      }
    );




  }, []);

  function ocultarIconos() {
    $("#buena_nueva").css("visibility", "hidden")
    $("#dabar").css("visibility", "hidden")
    $("#kabai").css("visibility", "hidden")
    $("#aletheian").css("visibility", "hidden")
    $("#biblioteca").css("visibility", "hidden")
    $("#humanitas").css("visibility", "hidden")
    $("#posgrados").css("visibility", "hidden")
    $("#cancha").css("visibility", "hidden")
    $("#plazoleta").css("visibility", "hidden")
    $("#entradanormandia").css("visibility", "hidden")
    $("#entradaprincipal").css("visibility", "hidden")
    $("#parqueadero2").css("visibility", "hidden")
    $("#parqueaderomotos2").css("visibility", "hidden")
    $("#parqueaderoadm2").css("visibility", "hidden")
    $("#rioconsota2").css("visibility", "hidden")

    $("#parquebanderas2").css("visibility", "hidden")

    $("#buena_nuevabtn").css("visibility", "hidden")
    $("#dabarbtn").css("visibility", "hidden")
    $("#kabaibtn").css("visibility", "hidden")
    $("#aletheianbtn").css("visibility", "hidden")
    $("#bibliotecabtn").css("visibility", "hidden")
    $("#humanitasbtn").css("visibility", "hidden")
    $("#posgradosbtn").css("visibility", "hidden")
  }




  return (

    <AnimationOnScroll animateIn="animate__fadeIn">

      <div className="container_mapa" id="mapa">
        <div className="imagen_mapa">

          <img className="mapa222" src={ImagenMapa2} alt="some value" />
          <div className="contenedorLetras">
            <h5 onClick={ocultarIconos}>Recorre virtualmente</h5>
            <h5 id="segundotitulo" onClick={ocultarIconos}>nuestro campus</h5>

          </div>
          <div className="places_icons buenanueva">
            <i onClick={Piso1} data-target="#Buena1" style={{ color: "#2880c0" }} className="bi bi-geo-alt-fill" id="geo_1" data-toggle="modal"></i>
            <p className="title_bn" id="buena_nueva">Buena Nueva</p>
            <div className="container_btn" id="buena_nuevabtn">
              <button onClick={Piso3} data-toggle="modal" data-target="#Capsi">CAPSI</button>
              <button onClick={Piso1} data-toggle="modal" data-target="#Buena1">Piso 1</button>
              <button onClick={Piso2} data-toggle="modal" data-target="#Buena2">Piso 2</button>

            </div>
          </div>
          <div className="places_icons dabar">
            <i onClick={Piso1} style={{ color: "#765d97" }} className="bi bi-geo-alt-fill" id="geo_2" data-toggle="modal" data-target="#Dabar1"></i>
            <p className="title_bn" id="dabar">Dabar</p>
            <div className="container_btn" id="dabarbtn">
              <button onClick={Piso1} data-toggle="modal" data-target="#Dabar1">Piso 1</button>
              <button onClick={Piso2} data-toggle="modal" data-target="#Dabar2">Piso 2</button>
              <button onClick={Piso3} data-toggle="modal" data-target="#Dabar3">Piso 3</button>
            </div>
          </div>
          <div className="places_icons kabai">
            <i onClick={Piso1} style={{ color: "#55b0a2" }} className="bi bi-geo-alt-fill" id="geo_3" data-toggle="modal" data-target="#Kabai1"></i>
            <p className="title_bn" id="kabai">Kabai</p>
            <div className="container_btn" id="kabaibtn">
              <button onClick={Piso1} data-toggle="modal" data-target="#Kabai1">Piso 1</button>
              <button onClick={Piso2} data-toggle="modal" data-target="#Kabai2">Piso 2</button>
              <button onClick={Piso3} data-toggle="modal" data-target="#Kabai3">Piso 3</button>
            </div>
          </div>
          <div className="places_icons aletheian">
            <i onClick={Piso1} style={{ color: "#d5a833" }} className="bi bi-geo-alt-fill" id="geo_4" data-toggle="modal" data-target="#Ale1"></i>
            <p className="title_bn" id="aletheian">Aletheia</p>
            <div className="container_btn" id="aletheianbtn">
              <button onClick={Piso1} data-toggle="modal" data-target="#Ale1">Piso 1</button>
              <button onClick={Piso2} data-toggle="modal" data-target="#Ale2">Piso 2</button>
              <button onClick={Piso3} data-toggle="modal" data-target="#Ale3">Piso 3</button>
            </div>
          </div>
          <div className="places_icons biblioteca">
            <i onClick={Piso1} style={{ color: "#81a833" }} className="bi bi-geo-alt-fill" id="geo_5" data-toggle="modal" data-target="#Biblioteca1"></i>
            <p className="title_bn" id="biblioteca">Biblioteca</p>
            <div className="container_btn" id="bibliotecabtn">
              <button onClick={Piso1} data-toggle="modal" data-target="#Biblioteca1">Piso 1</button>
              <button onClick={Piso2} data-toggle="modal" data-target="#Biblioteca2">Piso 2</button>
              <button onClick={Piso3} data-toggle="modal" data-target="#Biblioteca3">Piso 3</button>
              <button onClick={Piso4} data-toggle="modal" data-target="#Biblioteca4">Piso 4</button>
            </div>
          </div>
          <div className="places_icons posgrados">
            <i onClick={Piso1} style={{ color: "#c66169" }} className="bi bi-geo-alt-fill" id="geo_7" data-toggle="modal" data-target="#Humanitas1"></i>
            <p className="title_bn" id="posgrados">Humanitas</p>
            <div className="container_btn" id="posgradosbtn">
              <button onClick={Piso1} data-toggle="modal" data-target="#Humanitas1">Piso 1</button>
              <button onClick={Piso2} data-toggle="modal" data-target="#Humanitas2">Piso 2</button>

            </div>
          </div>
          <div className="places_icons humanitas">
            <i onClick={Piso1} style={{ color: "#812381" }} className="bi bi-geo-alt-fill" id="geo_6" data-toggle="modal" data-target="#Posgrados1"></i>
            <p className="title_bn" id="humanitas">Posgrados</p>
            <div className="container_btn" id="humanitasbtn">
              <button onClick={Piso1} data-toggle="modal" data-target="#Posgrados1">Piso 1</button>
              <button onClick={Piso2} data-toggle="modal" data-target="#Posgrados2">Piso 2</button>
              <button onClick={Piso3} data-toggle="modal" data-target="#Posgrados3">Piso 3</button>
            </div>
          </div>
          <div className="places_icons cancha">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_8" data-toggle="modal" data-target="#Canchas"></i>
            <p className="title_bn" id="cancha">Canchas</p>

          </div>

          <div className="places_icons plazoleta">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_9" data-toggle="modal" data-target="#Plazoleta"></i>
            <p className="title_bn" id="plazoleta">Plazoleta 14 de Febrero</p>

          </div>

          <div className="places_icons entradaprincipal">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_10" data-toggle="modal" data-target="#Entradaprincipal"></i>
            <p className="title_bn" id="entradaprincipal">Entrada principal</p>

          </div>

          <div className="places_icons entradanormandia">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_11" data-toggle="modal" data-target="#Entradanormandia"></i>
            <p className="title_bn" id="entradanormandia">Entrada 2- Peatonal</p>
          </div>

          <div className="places_icons parqueadero">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_12" data-toggle="modal" data-target="#parqueadero"></i>
            <p className="title_bn" id="parqueadero2">Parqueadero </p>
          </div>

          <div className="places_icons parqueaderomotos">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_13" data-toggle="modal" data-target="#parqueaderomotos"></i>
            <p className="title_bn" id="parqueaderomotos2">Parqueadero motos</p>
          </div>

          <div className="places_icons rioconsota">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_14" data-toggle="modal" data-target="#rioconsota"></i>
            <p className="title_bn" id="rioconsota2">Río Consotá</p>
          </div>

          <div className="places_icons parqueaderoadm">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_15" data-toggle="modal" data-target="#parqueaderoadm"></i>
            <p className="title_bn" id="parqueaderoadm2">Parqueadero de docentes y administrativos </p>
          </div>

          <div className="places_icons parquebanderas">
            <i onClick={Piso1} className="bi bi-geo-alt-fill" id="geo_16" data-toggle="modal" data-target="#parquebanderas"></i>
            <p className="title_bn" id="parquebanderas2">Plazoleta de Banderas</p>
          </div>

        </div>
        <div className="tabla_sitios">

          <div className="tabla_contenido">
            <table className="table">
              <thead style={{ backgroundColor: "#d5a833" }}>
                <tr>
                  <th onClick={Menu}>Aletheia</th>
                </tr>
              </thead>
              <tbody className="dobyAletheia bodytable selecTable">
                <div className="contenido_tabla">
                  <tr>
                    <th onClick={Piso1} className="lugar cafeteriaroja" data-toggle="modal" data-target="#Ale1">Cafetería roja</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar tiendau" data-toggle="modal" data-target="#Ale1">Tienda universitaria</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar gmercadeo" data-toggle="modal" data-target="#Ale1">Gestión de Mercadeo</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar banco" data-toggle="modal" data-target="#Ale1">Banco AV Villas</th>
                  </tr>

                  <tr>
                    <th onClick={Piso2} className="lugar financiera" data-toggle="modal" data-target="#Ale2">Financiera</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar tesoreria" data-toggle="modal" data-target="#Ale2">Tesorería </th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar admisiones " data-toggle="modal" data-target="#Ale2">Admisiones y Registro </th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar rectoria" data-toggle="modal" data-target="#Ale2">Rectoría </th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar vice " data-toggle="modal" data-target="#Ale2">Vicerrectoría Académica </th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar gh" data-toggle="modal" data-target="#Ale3">Gestión Tecnológica  </th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar sc1" data-toggle="modal" data-target="#Ale3">Sala de sistemas </th>
                  </tr>
                </div>
              </tbody>
            </table>
            <table>
              <thead style={{ backgroundColor: "#55b0a1" }}>
                <tr>
                  <th onClick={Menu2}>Humanitas</th>
                </tr>
              </thead>
              <tbody className="dobyHumanitas bodytable selecTable">
                <div className="contenido_tabla">
                  <tr>
                    <th onClick={Piso3} className="lugar capilla" data-toggle="modal" data-target="#Humanitas1">Capilla</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar gestionh" data-toggle="modal" data-target="#Humanitas1">Gestión Humana</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar practicas" data-toggle="modal" data-target="#Humanitas1">Prácticas y Graduados </th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar hsalones" data-toggle="modal" data-target="#Humanitas1">Salones</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar estudiantes" data-toggle="modal" data-target="#Humanitas2">Sala del Estudiante</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar comunicaciones " data-toggle="modal" data-target="#Humanitas2">Comunicaciones</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar psicologia" data-toggle="modal" data-target="#Humanitas2">Dirección de Psicología</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar ciuc" data-toggle="modal" data-target="#Humanitas2">Proyección Social y Centro de Idiomas</th>
                  </tr>
                </div>
              </tbody>
            </table>
            <table>
              <thead style={{ backgroundColor: "#765d96" }}>
                <tr>
                  <th onClick={Menu3}>Kabai</th>
                </tr>
              </thead>
              <tbody className="dobyKabai bodytable selecTable">
                <div className="contenido_tabla">
                  <tr>
                    <th onClick={Piso1} className="lugar cafeteria" data-toggle="modal" data-target="#Kabai1">Cafetería azul</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar cie" data-toggle="modal" data-target="#Kabai3">CIE</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar papeleria" data-toggle="modal" data-target="#Kabai1">Papelería </th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar vrp2" data-toggle="modal" data-target="#Kabai1">Vicerrectoría Proyecto de Vida</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar mercadeo" data-toggle="modal" data-target="#Kabai1">Dirección de Programa de Mercadeo</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar lab_psi" data-toggle="modal" data-target="#Kabai2">Laboratorio de psicología </th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar lab_neuro" data-toggle="modal" data-target="#Kabai2">Laboratorio de neurociencia </th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar programa_DA " data-toggle="modal" data-target="#Kabai2">Decanatura de Facultad de Ciencias Básicas e Ingeniería </th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar acuario" data-toggle="modal" data-target="#Kabai2">Dirección de Programas </th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar DCE" data-toggle="modal" data-target="#Kabai2">Decanatura de Facultad de Ciencias Económicas</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar emprendimiento" data-toggle="modal" data-target="#Kabai3">Sala de emprendimiento </th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar aulav1" data-toggle="modal" data-target="#Kabai3">Aula virtual 1</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar ciesalones" data-toggle="modal" data-target="#Kabai3">Aula virtual 2 </th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar bigdata" data-toggle="modal" data-target="#Kabai3">Laboratorio de big data</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar laboratorio" data-toggle="modal" data-target="#Kabai3">Laboratorio visualización avanzada</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar salvip" data-toggle="modal" data-target="#Kabai3">Sala VIP</th>
                  </tr>
                </div>
              </tbody>

            </table>
            <table>
              <thead style={{ backgroundColor: "#2880c0" }}>
                <tr>
                  <th onClick={Menu4}>Dabar</th>
                </tr>
              </thead>
              <tbody className="dobyDabar bodytable selecTable">
                <div className="contenido_tabla">
                  <tr>
                    <th onClick={Piso1} className="lugar auditorio " data-toggle="modal" data-target="#Dabar1">Auditorio Dabar</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar vrp" data-toggle="modal" data-target="#Dabar1">Centro de Familia</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar consejos" data-toggle="modal" data-target="#Dabar1">Sala de consejos</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar pb" data-toggle="modal" data-target="#Dabar1">Punto de Bolsa</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar teatrino" data-toggle="modal" data-target="#Dabar2">Teatrino</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar centrocultura" data-toggle="modal" data-target="#Dabar2">Centro de Actividades Culturales</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar oval2" data-toggle="modal" data-target="#Dabar2">Oval 2</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar oval3" data-toggle="modal" data-target="#Dabar3">Oval 3</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar salamac" data-toggle="modal" data-target="#Dabar3">Sala MAC</th>
                  </tr>
                </div>
              </tbody>
            </table>
            <table>
              <thead style={{ backgroundColor: "#c36066" }}>
                <tr>
                  <th onClick={Menu5}>Biblioteca</th>
                </tr>
              </thead>
              <tbody className="dobyBiblioteca bodytable selecTable">
                <div className="contenido_tabla">
                  <tr>
                    <th onClick={Piso1} className="lugar infantil" data-toggle="modal" data-target="#Biblioteca1">Sala infantil</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar bibliocafe" data-toggle="modal" data-target="#Biblioteca1">Bibliocafé</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar dinv" data-toggle="modal" data-target="#Biblioteca1">Dirección de Investigación</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar referencia" data-toggle="modal" data-target="#Biblioteca1">Referencia</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar prestamos" data-toggle="modal" data-target="#Biblioteca2">Circulación y préstamos</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar emeroteca" data-toggle="modal" data-target="#Biblioteca2">Hemeroteca</th>
                  </tr>

                  <tr>
                    <th onClick={Piso3} className="lugar cubiculosb" data-toggle="modal" data-target="#Biblioteca3">Cubículos</th>
                  </tr>

                  <tr>
                    <th onClick={Piso3} className="lugar salag" data-toggle="modal" data-target="#Biblioteca3">Sala general</th>
                  </tr>

                  <tr>
                    <th onClick={Piso3} className="lugar salagrupal" data-toggle="modal" data-target="#Biblioteca3">Sala grupal</th>
                  </tr>

                  <tr>
                    <th onClick={Piso3} className="lugar salap" data-toggle="modal" data-target="#Biblioteca3">Sala de profesores</th>
                  </tr>

                  <tr>
                    <th onClick={Piso4} className="lugar pinacoteca" data-toggle="modal" data-target="#Biblioteca4">Pinacoteca</th>
                  </tr>
                </div>
              </tbody>
            </table>

            <table>
              <thead style={{ backgroundColor: "#81a833" }}>
                <tr>
                  <th onClick={Menu6}>Buena Nueva</th>
                </tr>
              </thead>
              <tbody className="dobyBuenaNueva bodytable selecTable">
                <div className="contenido_tabla">
                  <tr>
                    <th onClick={Piso2} className="lugar capsi" data-toggle="modal" data-target="#Capsi">CAPSI</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar prodilab" data-toggle="modal" data-target="#Buena1">Prodilab</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar oval1" data-toggle="modal" data-target="#Buena1">Oval 1</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar arquitectura" data-toggle="modal" data-target="#Buena1">Taller de diseño industrial</th>
                  </tr>
                  <tr>
                    <th onClick={Piso1} className="lugar medio" data-toggle="modal" data-target="#Buena1">Centro de Medios</th>
                  </tr>
                  <tr>
                    <th onClick={Piso3} className="lugar gym" data-toggle="modal" data-target="#Buena2">Gimnasio</th>
                  </tr>
                </div>
              </tbody>
            </table>

            <table>
              <thead style={{ backgroundColor: "#d5a832" }}>
                <tr>
                  <th onClick={Menu7}>Posgrados</th>
                </tr>
              </thead>
              <tbody className="dobyPosgrados bodytable selecTable">
                <div className="contenido_tabla">
                  <tr>
                    <th onClick={Piso1} className="lugar piso1posgrados" data-toggle="modal" data-target="#Posgrados1">Salones</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar piso2posgrados" data-toggle="modal" data-target="#Posgrados2">Dep. Humanidades y LER</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar piso3posgrados" data-toggle="modal" data-target="#Posgrados2">Secretaría Humanidades y LER</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar piso3posgrados" data-toggle="modal" data-target="#Posgrados2">Dirección Humanidades</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar piso3posgrados" data-toggle="modal" data-target="#Posgrados2">Dirección de Licenciatura de Educación Religiosa</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar piso3posgrados" data-toggle="modal" data-target="#Posgrados2">Profesional de posgrados</th>
                  </tr>
                  <tr>
                    <th onClick={Piso2} className="lugar piso3posgrados" data-toggle="modal" data-target="#Posgrados2">Posgrados Pedagogía y Desarrollo Humano</th>
                  </tr>
                </div>
              </tbody>
            </table>
          </div>
        </div>


      </div>
    </AnimationOnScroll>

  );
}

export default Mapa;


function Piso1() {

  const matches = document.querySelectorAll("div.pisoactivo");
  $(matches).removeClass("pisoactivo");
  $(".iconosPisos div:nth-child(1)").addClass("pisoactivo");

}

function Piso2() {

  const matches = document.querySelectorAll("div.pisoactivo");
  $(matches).removeClass("pisoactivo");
  $(".iconosPisos div:nth-child(2)").addClass("pisoactivo");

}

function Piso3() {

  const matches = document.querySelectorAll("div.pisoactivo");
  $(matches).removeClass("pisoactivo");
  $(".iconosPisos div:nth-child(3)").addClass("pisoactivo");

}

function Piso4() {

  const matches = document.querySelectorAll("div.pisoactivo");
  $(matches).removeClass("pisoactivo");
  $(".iconosPisos div:nth-child(4)").addClass("pisoactivo");

}
