import React from "react";
import Contactos from "../views/Contactos"
import Footer from "../views/Footer";
import Header from "../views/Header";
import IconsFijos from "../views/IconsFijos";
import Ayuda from "../views/Ayuda";
import styled from "styled-components";
import { useParams } from 'react-router-dom';
import { useEffect } from "react";
import Slidernav from "../views/Slidernav";
import "../css/Search.css"
import $ from "jquery";



function Search() {
  const { producto } = useParams();

  useEffect(() => {

    const arrayvostas = [
      {
        nombre: "Centro de Innovación Educativa",
        apellido: "cie"
      },
      {
        nombre: "CIE",
        apellido: "cie"
      },
      {
        nombre: "Diplomado En Evaluación Mediada por Tic",
        apellido: "diplomado_EMD"
      },
      {
        nombre: "Rectoría",
        apellido: "rectoria"
      },
      {
        nombre: "Admisiones y Registro Académico",
        apellido: "admisiones_registro"
      },
      {
        nombre: "Vicerrectoría Académica",
        apellido: "vicerrectoria"
      },
      {
        nombre: "Vicerrectoría de Proyecto de Vida",
        apellido: "vicerrectoria_proyecto_de_vida"
      },
      {
        nombre: "Admisiones y registro",
        apellido: "admisiones_registro"
      },
      {
        nombre: "Gestión Financiera",
        apellido: "gestion_financiera"
      },
      {
        nombre: "Centro de Idiomas",
        apellido: "centro_idiomas"
      },
      {
        nombre: "Internacionalización",
        apellido: "internacionalizacion"
      },
      {
        nombre: "Biblioteca",
        apellido: "biblioteca"
      },
      {
        nombre: "Prácticas Académicas",
        apellido: "practicas_graduados"
      },
      {
        nombre: "Mi primer paso digital",
        apellido: "paso_digital"
      },
      {
        nombre: "Campus al día",
        apellido: "campus_dia"
      },
      {
        nombre: " Gestión de la citación y referenciación académica",
        apellido: "gestion_citacion"
      },
      {
        nombre: "Maestría en innovación educativa",
        apellido: "maestria_innovacion"
      },
      {
        nombre: "Diplomado habilidades para el aprendizaje en línea",
        apellido: "aprendizaje_en_linea"
      },
    ]
    let i = 0;
    arrayvostas.forEach((element) => {
      if (
        element.nombre
          .toString()
          .toLowerCase()
          .includes(producto.toString().toLowerCase())
      ) {
        $("#result_search").append(
          `   
          <div><a href="/info/${element.apellido}">${element.nombre} </a> </div> `
        );
        i++
      } else {
        console.log("no" + element.nombre);
      }
    });
    $("#cantidad_search").html(
      `<h1>Coincidencias encontradas para ${producto}: ${i}</h1>`
    );
  });


  return (
    <>
      <Ayuda />

      <div className="containerBuscador">
        <div style={{ height: "80px", backgroundColor: "#77383E", position: "fixed", top: "0", width: "100%" }}>
          <Header />
        </div>
        <Slidernav />
        <IconsFijos />
        <ContainerCantidad id="cantidad_search"></ContainerCantidad>
        <ContainerResultados id='result_search'>
        </ContainerResultados>
        <section className='contactos_fijos' id='contactos_fijos'>
          <Contactos />
        </section>
        <Footer className="footer_position" />

        <div style={{ display: "none" }}>
        </div>
      </div>
    </>
  )
}

const ContainerResultados = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-wrap: wrap;
  margin-bottom:45px;
`;

const ContainerCantidad = styled.div`
width: 100%
`

export default Search;

