import React from "react";
import Header from "../views/Header";
import Slidernav from "../views/Slidernav";
import "../css/Informativas.css"

function Informativas(props) {

    return (
        <>
            <Header />
            <Slidernav/>
            <div className="containerimg">
                <div className="Titulo">
                    <div>
                        <h3>{props.titulo}</h3>
                    </div>
                    <div>
                        <p>{props.descripcion}</p>
                    </div>
                </div>
                <div className="itemDeco">
                    <div className="span_barra" style={{ backgroundColor: props.color }}>
                        <span></span>

                    </div>
                    <div className="logodeco" style={{ backgroundColor: props.color }}>
                        <img loading="lazy" src={props.logo}></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Informativas;