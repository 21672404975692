import React from "react";
import "../css/PersonasSlider.css";

function PersonasSlider({ children }) {

  return (
    <li className="ml-2">
      {children}
    </li>
  )
}

export default PersonasSlider