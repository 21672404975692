import React from "react";
import "../css/Ofertas.css";
import { useEffect } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import $ from "jquery";

function Ofertas({ children }) {
  useEffect(() => {

    let radio = document.getElementById("todos");
    let continua = document.querySelectorAll("#continua");
    let facultad = document.querySelectorAll("#fac_id");
    let facultad_arquitectura = document.querySelectorAll(".FAD");
    let facultad_cienciasHumanas = document.querySelectorAll(".CHSE");
    let facultad_cienciasEconomicas = document.querySelectorAll(".CEA");
    let facultad_cienciasBasicas = document.querySelectorAll(".CBI");
    let facultadesfiltro = document.querySelectorAll(".itemInputFacultad");

    radio.addEventListener("click", function () {
      $(facultadesfiltro).css("display", "none")

      continua.forEach((data) => {
        $(data).show();
      });

      facultad.forEach((data2) => {
        $(data2).show();
      });
    });

    $("#facultad_arquitectura").click(function () {
      $(facultadesfiltro).css("display", "flex")

      continua.forEach((data) => {
        $(data).hide();
      });

      facultad.forEach((data2) => {
        $(data2).hide();
      });

      facultad_arquitectura.forEach((data3) => {
        $(data3).show();
      })
    });


    $("#facultad_cienciasHumanas").click(function () {
      $(facultadesfiltro).css("display", "flex")

      continua.forEach((data) => {
        $(data).hide();
      });

      facultad.forEach((data2) => {
        $(data2).hide();
      });

      facultad_cienciasHumanas.forEach((data3) => {
        $(data3).show();
      })
    });

    $("#facultad_cienciasEconomicas").click(function () {
      $(facultadesfiltro).css("display", "flex")

      continua.forEach((data) => {
        $(data).hide();
      });

      facultad.forEach((data2) => {
        $(data2).hide();
      });

      facultad_cienciasEconomicas.forEach((data3) => {
        $(data3).show();
      })
    });

    $("#facultad_cienciasBasicas").click(function () {
      $(facultadesfiltro).css("display", "flex")

      continua.forEach((data) => {
        $(data).hide();
      });

      facultad.forEach((data2) => {
        $(data2).hide();
      });

      facultad_cienciasBasicas.forEach((data3) => {
        $(data3).show();
      })
    });
    

    $("#facultad").click(function () {
      continua.forEach((data) => {
        $(data).hide();
        $(facultadesfiltro).css("display", "none")

      });

      facultad.forEach((data2) => {
        $(data2).show();
/*         $(facultadesfiltro).css("display", "flex")
 */
      });
    });

    $("#formacion").click(function () {
      continua.forEach((data) => {
        $(data).show();
        $(facultadesfiltro).css("display", "none")

      });

      facultad.forEach((data2) => {
        $(data2).hide();
        $(facultadesfiltro).css("display", "none")

      });
    });
  });

  return (


    <div className="items_card" id="items_card2">
      {children}
    </div>

  );
}

export default Ofertas;
