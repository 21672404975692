import React from "react";
import $ from "jquery";
import 'jquery-ui-dist/jquery-ui';
import "../css/IconsFijos.css";
const Silla = require(`../img/silla.png`)



function IconsFijos({ children }) {


  function Menu() {
    $(".ContainerSliderAyuda").toggle("slide", { direction: "right" }, 500);
    setTimeout(() => {
      $(".ContainerSliderAyuda").css("position", "absolute")
    }, 1000);



  }



 



  return (
    <div className="position-sticky">
      <div className="icons_fijos">
        <img src={Silla} onClick={Menu} alt="Silla" />
        {children}
      </div>

    </div>
  );
}

export default IconsFijos;